import { Store } from 'vuex';
import { RootState } from '~/@api/store.types';
import {
	LOADING_A_SET_LOADING,
	LOADING_G_IS_LOADING,
	LOADING_M_RESET_STATE,
} from '~/@constants/store';
import { importLogger, importRunTask } from '~/app-utils/dynamic-imports';

export default function (store: Store<RootState>) {
	return {
		setLoading: async (name: string, loading: boolean) => {
			const { runTaskWithPromise } = await importRunTask();

			await runTaskWithPromise(async () => {
				await store.dispatch(LOADING_A_SET_LOADING, { name, loading }, { root: true });
			});
		},

		doWithLoader: async <T>(name: string, runnable: () => Promise<T>): Promise<T> | undefined => {
			try {
				store.dispatch(LOADING_A_SET_LOADING, { name, loading: true }, { root: true });

				return await runnable();
			} catch (e) {
				importLogger().then((logger) => {
					logger.default.error('Something went wrong while loading.', e);
				});
			} finally {
				store.dispatch(LOADING_A_SET_LOADING, { name, loading: false }, { root: true });
			}
		},

		isLoading: (): boolean => store.getters[LOADING_G_IS_LOADING],

		resetState: () => store.commit(LOADING_M_RESET_STATE, null, { root: true }),
	};
}
