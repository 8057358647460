import type { Store } from 'vuex';
import { useStoreModule } from '../useStoreModule';
import messageboxStore from './messagebox.store';
import messageboxApi from './messagebox.api';
import { MessageboxState, RootState } from '~/@api/store.types';
import { MESSAGEBOX } from '~/@constants/store';

export function useMessageboxStore(store: Store<RootState>) {
	return useStoreModule<MessageboxState, ReturnType<typeof messageboxApi>>(
		MESSAGEBOX,
		store,
		messageboxStore,
		messageboxApi,
	);
}
