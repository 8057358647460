import type { Store } from 'vuex';
import { useStoreModule } from '../useStoreModule';
import formsStore from './forms.store';
import formsApi from './forms.api';
import { FormsState, RootState } from '~/@api/store.types';
import { FORMS } from '~/@constants/store';

export function useFormsStore(store: Store<RootState>) {
	return useStoreModule<FormsState, ReturnType<typeof formsApi>>(
		FORMS,
		store,
		formsStore,
		formsApi,
	);
}
