// Blocking time optimized aca recommendations from
// https://github.com/nuxt/nuxt.js/discussions/9061#discussioncomment-539483

// Importing dependencies - statically ------
import { Context } from '@nuxt/types';
import { BeforeInstallPromptEvent } from '~/@api/store.types';
import clientPluginOnNuxtReady from '~/plugins/clientPluginHandler';
import { importLogger } from '~/app-utils/dynamic-imports';
import { useInstallpromptStore } from '~/@api/store/installpromptApi';

// eslint-disable-next-line require-await
async function PluginIntegration(context: Context) {
	const { api: installpromptApi } = useInstallpromptStore(context.store);

	window.addEventListener('beforeinstallprompt', async (event: BeforeInstallPromptEvent) => {
		// Prevent Chrome 67 and earlier from automatically showing the prompt
		event.preventDefault();
		// Stash the event so it can be triggered later.
		installpromptApi.beforeinstall(event);

		// Track the user choice outcome
		const choiceResult = await event.userChoice;
		const { default: Logger } = await importLogger();

		if (choiceResult.outcome === 'accepted') {
			Logger.info('User accepted the A2HS prompt');
			installpromptApi.accept();
		} else {
			Logger.info('User dismissed the A2HS prompt');
			installpromptApi.dismiss();
		}
	});
	window.addEventListener('appinstalled', () => {
		installpromptApi.trackPWA('success');
	});
}

// Defer until Nuxt is ready
export default function (context: Context, _) {
	clientPluginOnNuxtReady(() => PluginIntegration(context));
}
