// Blocking time optimized aca recommendations from
// https://github.com/nuxt/nuxt.js/discussions/9061#discussioncomment-539483
import { Context } from '@nuxt/types';
import { NuxtCookies } from 'cookie-universal-nuxt';
import clientPluginOnNuxtReady from '~/plugins/clientPluginHandler';
import { importSetSafeTimeout } from '~/app-utils/dynamic-imports';
import {
	anyConsentDataExists,
	cmpDebug,
	consentCascadeChecks,
	getReasonableCmpData,
	updateConsentDataFallbacks,
} from '~/app-utils/consentmanager';

cmpDebug('nuxt plugin loaded');

(window as any).osp = (window as any).osp || {};
(window as any).osp.cmp = (window as any).osp.cmp || {};

interface CmpWindowObject {
	__cmp?: (eventName?: string, cmpObject?: { [key: string]: any }, subtype?: string) => {};
	osp?: {
		localStorage?: { [key: string]: any };
		cmp?: {
			consentCascadeChecks: (vendorId: string, cookies?: NuxtCookies) => boolean;
		};
	};
	checkCMPVendorConsent: (vendorId: string) => Promise<boolean>;
	gtmDebug: (messageOrPrefix, prefixedMessage) => void;
}

// Add event listeners to keep local fallback consent data up-to-date
function addCmpEventListeners(context: Context) {
	cmpDebug('addCmpEventListeners(context)');

	const cmpObject = (window as any).__cmp;

	if (cmpObject === 'undefined') {
		return false;
	}

	cmpObject('addEventListener', [
		'consentscreen',
		() => {
			performance.mark('[CMP] consentscreen 2');

			const durationA = performance.measure(
				'[CMP] initiate - consentscreen event 2',
				'[CMP] initiate',
				'[CMP] consentscreen 2',
			);

			const durationB = performance.measure(
				'[CMP] plugin - consentscreen event 2',
				'[CMP] plugin loaded',
				'[CMP] consentscreen 2',
			);

			cmpDebug(`initiate -> consentscreen event 2 after ${durationA} ms`);
			cmpDebug(`plugin -> consentscreen event 2 after ${durationB} ms`);
		},
		false,
	]);

	// Try to show cmp screen as fast as possible when either consentStatus is already known, otherwise check if
	// any consent data from previous visits already exists locally
	if (
		cmpObject('consentStatus')?.userChoiceExists === false &&
		!anyConsentDataExists(context.$cookies)
	) {
		cmpDebug('showScreen by plugin');
		cmpObject('showScreen');
	}

	// If consent data is already available, update the local fallback data
	if (getReasonableCmpData()) {
		cmpDebug('reasonable __cmp data available');
		updateConsentDataFallbacks(context);
	} else {
		cmpDebug('no reasonable __cmp data available');
	}

	['settings', 'consent'].forEach((eventName) => {
		cmpObject('addEventListener', [
			eventName,
			() => {
				cmpDebug(`event ${eventName} occurred`);

				if (eventName === 'settings' && !!cmpObject('consentStatus').userChoiceExists) {
					cmpDebug(`close opened screen as choice available in __cmp`);
					cmpObject('close');
				}

				updateConsentDataFallbacks(context);
			},
			false,
		]);
	});

	cmpDebug('consent listeners added');

	return true;
}

// eslint-disable-next-line require-await
async function PluginIntegration(context: Context) {
	cmpDebug('PluginIntegration');

	// If checkCMPVendorConsent already exists and reasonable cmpDataLoaded, no need to create fallback logic
	if (typeof (window as any).checkCMPVendorConsent === 'function' && !!getReasonableCmpData()) {
		return;
	}

	const cmpWindowObject = window as unknown as CmpWindowObject;

	// Cascade consent checks with fallbacks to find any previous set consent data
	cmpWindowObject.osp.cmp.consentCascadeChecks = consentCascadeChecks;

	const waitUntilNextCheck = 20; // Milliseconds for re-try
	let timeLeft = 10000; // Milliseconds until abort

	function tryAddCmpListener() {
		if (addCmpEventListeners(context)) return;

		if (timeLeft <= 0) {
			cmpDebug('adding cmpEventListeners failed due to timeout. No cmp base object found.');
			return;
		}

		importSetSafeTimeout().then(({ setSafeTimeout }) => {
			setSafeTimeout(() => {
				timeLeft -= waitUntilNextCheck;
				tryAddCmpListener();
			}, waitUntilNextCheck);
		});
	}

	tryAddCmpListener();

	// Make sure not to overwrite maybe already existing check on current window before assigning interim function
	if (typeof (window as unknown as CmpWindowObject).checkCMPVendorConsent === 'function') return;

	// Add interim checkCMPVendorConsent function with local fallback checks until GTM is loaded and received first
	// cmpEvent (= cmp ready to use live data). Then function will be overwritten by function that waits for real time
	// data from cmp to handle GTM vendor consent
	(window as unknown as CmpWindowObject).checkCMPVendorConsent = function (cmpVendorId: string) {
		cmpDebug('interim consent check function defined');

		return Promise.resolve(cmpWindowObject.osp.cmp.consentCascadeChecks(cmpVendorId));
	};
}

// Defer until Nuxt is ready
export default function (context: Context) {
	if (!context.$config.env.CMP_ENABLED) return;

	performance.mark('[CMP] plugin loaded');
	cmpDebug('nuxt plugin default function');

	clientPluginOnNuxtReady(() => PluginIntegration(context));
}
