// Blocking time optimized aca recommendations from
// https://github.com/nuxt/nuxt.js/discussions/9061#discussioncomment-539483

// Importing dependencies - statically ------
import { Context } from '@nuxt/types';
import {
	importLodashGet,
	importLodashSet,
	importVue,
	importVueCustomElement,
} from '~/app-utils/dynamic-imports';

async function PluginIntegration(context: Context) {
	// Wait until all dependencies were loaded asynchronously in parallel
	const dynamicImportResponses = await Promise.all([importVue(), importVueCustomElement()]);

	const Vue = dynamicImportResponses[0].default;
	const VueCustomElement = dynamicImportResponses[1].default;

	const slotRequests = new Map<string, (element) => void>();
	const knowInjectionSlots = new Map<string, HTMLElement>();

	Vue.use(VueCustomElement);

	/**
	 * This function is called by the injection slot in connectedCallback
	 * which is called on connect to DOM
	 * @param element
	 */
	async function registerSlot(element: any) {
		const { default: _get } = await importLodashGet();
		const id = _get(element, 'id');

		knowInjectionSlots.set(id, element as HTMLElement);

		if (slotRequests.has(id)) {
			slotRequests.get(id)(element as HTMLElement);
		}
	}

	/**
	 * Function that is registered globally and allows requesting injection slots asynchronously
	 * The function returns a promise that
	 * 1. resolved immediately if the slot ist already know
	 * 2. if the slot is not know it registers the slot and returns a promise that will resolve when slot registers
	 * The promise returns the HTMLElement that can be used to insert content
	 * @param id
	 */
	function requestInjectionSlot(id: string): Promise<HTMLElement> {
		if (knowInjectionSlots.has(id)) {
			return Promise.resolve(knowInjectionSlots.get(id));
		} else {
			const resolver = (resolve) => {
				slotRequests.set(id, resolve);
			};

			return new Promise(resolver);
		}
	}

	context.app.router.beforeResolve((_to, _from, next) => {
		knowInjectionSlots.clear();
		next();
	});

	const { default: _set } = await importLodashSet();

	_set(window, 'requestInjectionSlot', requestInjectionSlot);
	Vue.customElement(
		'injection-slot',
		{
			props: {
				content: { type: String, required: false },
			},
			render(createElement, _context) {
				return createElement('div', this.content);
			},
		},
		{
			connectedCallback() {
				registerSlot(this);
			},
		},
	);
}

// Run async but do not defer until nuxt is ready
// Reason: 3rd party code is injected that cause long tasks
// Pushing back in general is good to give more resources to required base loadings
// In this case it's not good as pushing back long tasks leads to increase TTI score
export default function (context: Context, _) {
	PluginIntegration(context);
}
