import { Context } from '@nuxt/types';
import { getPerformanceTrackingConfig } from '~/app-utils/tracking.utils';
import { OspNuxtRuntimeConfig } from '~/@types/OspNuxtRuntimeConfig';
import { importSpaRumThresholdReporter } from '~/app-utils/dynamic-imports';

async function PluginIntegration(context: Context) {
	const trackingConfig = getPerformanceTrackingConfig(context.$config as OspNuxtRuntimeConfig);

	if (!trackingConfig.spaRumThresholdReporting.active) {
		return;
	}

	// Only import plugin code is activated
	const { SpaRumThresholdReporter } = await importSpaRumThresholdReporter();
	SpaRumThresholdReporter.startWatch(trackingConfig.spaRumThresholdReporting);
}

// Do not defer this plugin until Nuxt is ready, to watch for occurring layout shifts right from the beginning
export default function (context: Context) {
	PluginIntegration(context);
}
