import type { Store } from 'vuex';
import { useStoreModule } from '../useStoreModule';
import overlayStore from './overlay.store';
import overlayApi from './overlay.api';
import { OverlayState, RootState } from '~/@api/store.types';
import { OVERLAY } from '~/@constants/store';

export function useOverlayStore(store: Store<RootState>) {
	return useStoreModule<OverlayState, ReturnType<typeof overlayApi>>(
		OVERLAY,
		store,
		overlayStore,
		overlayApi,
	);
}
