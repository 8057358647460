import { RouteConfig } from 'vue-router';
import { SpaType } from '@/generated/hybris-raml-api';
import { contentRoutes } from '@/routing/routes/content.route';
import { searchRoute } from '@/routing/routes/search.route';
import { categoryRoutes } from '@/routing/routes/category.route';
import { bikefinderRoutes } from '@/routing/routes/bike-finder.route';
import { brandsRoute } from '@/routing/routes/brands.route';
import { pdpRoute } from '@/routing/routes/pdp.route';
import { cartRoute } from '@/routing/routes/cart.route';
import { loginRoutes } from '@/routing/routes/login.route';
import { storefinderRoutes } from '@/routing/routes/store-finder.route';
import { checkoutRoute } from '@/routing/routes/checkout.route';
import { clubRoutes } from '@/routing/routes/club.route';
import { myAccountRoute } from '@/routing/routes/my-account.route';
import { newsletterRoutes } from '@/routing/routes/newsletter.route';
import { passwordResetRoutes } from '@/routing/routes/password-reset.route';

const defaultPath = '/:language(de|fr|it)/shop';

export const routes: RouteConfig[] = [
	{
		path: `${defaultPath}/_s/language`,
		meta: {
			routeType: 'template',
		},
	},
	...bikefinderRoutes(defaultPath),
	...checkoutRoute(defaultPath),
	...loginRoutes(defaultPath),
	...clubRoutes(defaultPath),
	...storefinderRoutes(defaultPath),
	...newsletterRoutes(defaultPath),
	...passwordResetRoutes(defaultPath),
	// STANDARD PAGES
	{
		path: defaultPath,
		component: () =>
			import('~/layouts/default-route/default-route.vue').then((m) => m.default || m),
		props: {
			footer: true,
			header: true,
		},
		meta: {
			routeType: 'template',
			spaType: SpaType.other,
		},
		children: [
			{
				path: 'maintenance(.html)?',
				meta: {
					routeType: 'template',
				},
			},
			...brandsRoute,
			cartRoute,
			...categoryRoutes,
			pdpRoute,
			...myAccountRoute(defaultPath),
			searchRoute,
			// Do not change the order here or add anything these routes must be the
			// Last matching the default shop prefix /<lang>/shop
			...contentRoutes,
			{
				name: 'defaultFallback',
				path: '*',
			},
		],
	},
];
