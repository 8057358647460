import type { Store } from 'vuex';
import { useStoreModule } from '../useStoreModule';
import searchUnsavedStore from './searchUnsaved.store';
import searchUnsavedApi from './searchUnsaved.api';
import { RootState, UnsavedSearchState } from '~/@api/store.types';
import { SEARCHUNSAVED } from '~/@constants/store';

export function useSearchUnsavedStore(store: Store<RootState>) {
	return useStoreModule<UnsavedSearchState, ReturnType<typeof searchUnsavedApi>>(
		SEARCHUNSAVED,
		store,
		searchUnsavedStore,
		searchUnsavedApi,
	);
}
