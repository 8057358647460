import { Context } from '@nuxt/types';
import { importRunTask } from '~/app-utils/dynamic-imports';
import { useLoadingStore } from '~/@api/store/loadingApi';

export default async (context: Context) => {
	if (process.client) {
		const { runTask } = await importRunTask();

		runTask(() => useLoadingStore(context.store).api.setLoading('middleware', true));
	}
};
