import type { GTM } from '~/@types/vue';
import { importLogger, importRunTask } from '~/app-utils/dynamic-imports';

export const track = (event: string, payload?: any, $gtm?: GTM) => {
	importRunTask().then(({ runTask }) => {
		runTask(() => {
			const data = {
				...payload,
				event,
				// https://www.simoahava.com/analytics/two-simple-data-model-tricks/
				// To prevent recursive merge from happening, you need to push the key _clear
				// with the value true in the same object where you push the keys you do not want to merge
				// with their counterparts. So the second push becomes:
				_clear: true,
			};

			if (!$gtm) {
				if (process.client) {
					(window as any).dataLayer = (window as any).dataLayer || [];

					(window as any).dataLayer.push(data);
				} else {
					importLogger().then(({ Logger }) => {
						Logger.error(
							new Error(
								'Could not push GTM event on serverside without given $gtm-parameter! Event was: ',
							),
							data,
						);
					});
				}
			} else {
				$gtm.push(data);
			}
		});
	});
};

export const isDYEnabled = () => !!(window as any).DY;

export const isGAEnabled = () => !!(window as any).google_tag_manager && (window as any).dataLayer;
