// Blocking time optimized aca recommendations from
// https://github.com/nuxt/nuxt.js/discussions/9061#discussioncomment-539483

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { Context } from '@nuxt/types';
import { merge } from 'lodash-es';
import { i18nKeys } from '@/generated/translations';
import { useI18nStore } from '~/@api/store/i18nApi';
import { useServerContextStore } from '~/@api/store/serverContextApi';

Vue.use(VueI18n);

Vue.mixin({
	computed: {
		$i18nKeys() {
			const i18nApiKeys = transformI18nKeys(
				Object.keys(this.$store.state?.i18n?.messages?.de || {}),
			);

			return merge(i18nKeys, i18nApiKeys);
		},
	},
});

export default function (context: Context, _) {
	context.app.i18n = new VueI18n({
		locale: useServerContextStore(context.store).state.session.language,
		messages: useI18nStore(context.store).state.messages,
	});
}

function transformI18nKeys(i18nKeys: string[]) {
	let i18nKeysObject = {};

	i18nKeys.forEach((i18nKey) => {
		i18nKeysObject = merge(i18nKeysObject, dotstringToObject(i18nKey));
	});

	return i18nKeysObject;
}

function dotstringToObject(dotstring: string) {
	const tmpObj = {};
	const keys = dotstring.split('.');
	const lastIndex = keys.length - 1;

	keys.reduce(function (r, a, ix) {
		r[a] = r[a] || (ix === lastIndex ? dotstring : {});
		return r[a];
	}, tmpObj);
}
