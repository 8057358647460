import { Context } from '@nuxt/types';
import _flatten from 'lodash-es/flatten';
import _groupBy from 'lodash-es/groupBy';
import _isEqual from 'lodash-es/isEqual';
import _omit from 'lodash-es/omit';
import { elapsedTime } from '@/node_modules/@osp/utils/src/performance';
import { getPageTypeMetaInfoFromRoute } from '~/routing/utils/spa-utils';
import { SEARCH_PAGE_NAME } from '~/@constants/global';
import { followRedirectIfGiven } from '~/app-utils/redirect';
import { useCmsContentStore } from '~/@api/store/cmsContentApi';
import { useDynamicyieldStore } from '~/@api/store/dynamicyieldApi';
import { useSearchStore } from '~/@api/store/searchApi';
import { useUserStore } from '~/@api/store/userApi';
import { PageTypes } from '~/@api/store.types';
import { SpaOtherId } from '~/generated/hybris-raml-api';
import { suggestedSearchUsageMarker } from '~/tracking/helpers/suggestedSearchUsageMarker';
import { internalSearch } from '~/tracking/events/internalSearch';

export default async (context: Context) => {
	if (shouldDoSearch(context)) {
		// Perform search if no data is stored in history state for current page
		const {
			brandCode,
			categoryCode,
			currentNavigation,
			pageSize,
			parsedPage,
			parsedQ,
			q,
			sort,
			text,
		} = calculateSearchParams(context);
		const { api: searchApi } = useSearchStore(context.store);

		if (isPaginationRequest(context)) {
			// If all params but the page is the same as before its just paging
			await searchApi.selectPage(parsedPage);
		} else {
			const request = searchApi.initialState().response;

			await searchApi.performSearch(
				{
					...request,
					brandCategoryCode: brandCode,
					categoryCode,
					text,

					...(currentNavigation && { currentNavigation: { code: currentNavigation } }),
					pagination: {
						...request.pagination,
						page: parsedPage,
						selectedOption: parseInt(pageSize || '48', 10),
					},
					...(parsedQ && {
						// Get first element of q-param (sort) and remove it from array
						sorts: { selectedOption: parsedQ.shift() },
						// Convert other q-params to facets:
						facets: convertQueryParamsToFacets(parsedQ),
					}),
					...(sort && { sorts: { selectedOption: sort } }),
				},
				!q,
			);

			const updateResponse = await updateCMSContent(context);

			if (updateResponse) {
				followRedirectIfGiven(updateResponse, context);

				return true;
			}

			trackUsageOfSuggestedSearch(context);
		}
	}

	elapsedTime('middleware: search');
};

// Condition to perform search if no data is stored in history state for current page
function shouldDoSearch(context: Context) {
	return (
		isSearchPage(context) &&
		(process.server ||
			(typeof window !== 'undefined' &&
				(context.from?.fullPath !== '/' || context.from?.name !== null) &&
				(!window.location.href.includes(context.route.fullPath) ||
					(window.location.search && !Object.keys(context.route.query).length) ||
					history.state?.component !== SEARCH_PAGE_NAME ||
					!history.state?.data)))
	);
}

function convertQueryParamsToFacets(parsedQ) {
	// 1. Group them by facetName (splt(:) and take [0])
	return (
		Object.entries(_groupBy(parsedQ, (qFacet) => qFacet.split(':')[0]))
			// 2. Map groups to facets
			.map((facet) => ({
				code: facet[0],
				name: null,
				// 2.1. Map group values to facetValues
				values: mapGroupValueToFacetValue(facet),
			}))
	);
}

function mapGroupValueToFacetValue(facet) {
	// Map group values to facetValues using split(:) and take [1]
	return facet[1].map((facetValue) =>
		facet[0] === 'priceValue'
			? {
					code: facet[0],
					selected: true,
					selectedMax: parseInt(facetValue.split(':')[1].split(' - ')[1], 10),
					selectedMin: parseInt(facetValue.split(':')[1].split(' - ')[0], 10),
				}
			: {
					code: facetValue.split(':')[1],
					selected: true,
				},
	);
}

export const isSearchPage = (context: Context) =>
	[PageTypes.PRODUCTSEARCH].includes(getPageType(context)) || isCategoryOrBrandPage(context);

export const isCategoryOrBrandPage = (context: Context) =>
	[PageTypes.BRAND, PageTypes.CATEGORY].includes(getPageType(context));

const getPageType = (context: Context) =>
	[...(context.route.meta || [])].reverse().find((meta) => meta.pageType)?.pageType;

export const isPaginationRequest = (context: Context) =>
	_isEqual(_omit(context.from?.query, 'page'), _omit(context.route.query, 'page')) &&
	_isEqual(context.from?.params, context.route.params) &&
	context.from?.path === context.route.path;

const calculateSearchParams = (context: Context) => {
	const genderNames = _flatten(
		useUserStore(context.store).state.user?.genders.map((gender) => [
			gender.code.toLowerCase(),
			gender.name.toLowerCase(),
		]),
	);
	const props = { ...context.route.query, ...context.route.params } as { [key: string]: string };
	const brandAndGender = (props.brandAndGender || '').split('-');
	const brandCode =
		props.brandCode ||
		(genderNames.includes(brandAndGender[0]) ? brandAndGender[1] : brandAndGender[0]);
	const { categoryCode, currentNavigation, page, pageSize, q, sort } = props;
	let text = props.text;
	let parsedQ = null;

	if (q) {
		const textSeperatorPosition = q.indexOf(':');

		text = props.text || q.substring(0, textSeperatorPosition);
		parsedQ = q.substring(textSeperatorPosition).match(/([^:]+:[^:]*)/g);
	}

	const parsedPage = parseInt(page || '1', 10);

	return {
		brandCode,
		categoryCode,
		currentNavigation,
		pageSize,
		parsedPage,
		parsedQ,
		q,
		sort,
		text,
	};
};

const updateCMSContent = (context: Context) => {
	useDynamicyieldStore(context.store).api.setPath(context.route.fullPath);

	const spaKey = getPageTypeMetaInfoFromRoute(context.store, context.route);

	if (
		spaKey.identifier.startsWith(SpaOtherId.search) ||
		spaKey.identifier.startsWith(SpaOtherId.searchEmpty)
	) {
		return useCmsContentStore(context.store).api.update(
			spaKey.spaType,
			spaKey.identifier.replace(
				/^[^:]*/,
				useSearchStore(context.store).state.response?.pagination?.totalCount > 0
					? SpaOtherId.search
					: SpaOtherId.searchEmpty,
			),
			false,
			context.route.fullPath,
			context.route.matched,
		);
	}
};

const trackUsageOfSuggestedSearch = (context: Context) => {
	if (suggestedSearchUsageMarker.marker) {
		internalSearch({
			searchResponse: useSearchStore(context.store).state.response,
			gender: useUserStore(context.store).state.user.gender.name,
		});

		// Remove storage and persist data only until the next page reload
		suggestedSearchUsageMarker.remove();
	}
};
