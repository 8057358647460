import type { Store } from 'vuex';
import { useStoreModule } from '../useStoreModule';
import productsStore from './products.store';
import productsApi from './products.api';
import { ProductsState, RootState } from '~/@api/store.types';
import { PRODUCTS } from '~/@constants/store';

export function useProductsStore(store: Store<RootState>) {
	return useStoreModule<ProductsState, ReturnType<typeof productsApi>>(
		PRODUCTS,
		store,
		productsStore,
		productsApi,
	);
}
