import type { Store } from 'vuex';
import { useStoreModule } from '../useStoreModule';
import cmsContentStore from './cmsContent.store';
import cmsContentApi from './cmsContent.api';
import { CmsContentState, RootState } from '~/@api/store.types';
import { CMSCONTENT } from '~/@constants/store';

export function useCmsContentStore(store: Store<RootState>) {
	return useStoreModule<CmsContentState, ReturnType<typeof cmsContentApi>>(
		CMSCONTENT,
		store,
		cmsContentStore,
		cmsContentApi,
	);
}
