import { ActionContext, Store } from 'vuex';
import { NuxtCookies } from 'cookie-universal-nuxt';
import { setSafeTimeout } from '@/node_modules/@osp/design-system/assets/js/utilities/timeout';
import { DebugSettings } from '@/node_modules/@osp/design-system/components/mixins/cls-base-mixin';
import { yieldToMain } from '@/node_modules/@osp/design-system/assets/js/utilities/runTask';
import { importRunTask, importLogger } from '~/app-utils/dynamic-imports';
import {
	DynamicYieldChoice,
	DynamicYieldCookie,
	DynamicYieldCredentials,
	DynamicYieldData,
	DynamicYieldPayload,
	DynamicYieldState,
	RootState,
} from '~/@api/store.types';
import {
	DYNAMICYIELD_A_FETCH,
	DYNAMICYIELD_A_REPORT,
	DYNAMICYIELD_CREDENTIAL_DYID,
	DYNAMICYIELD_CREDENTIAL_DYID_SERVER,
	DYNAMICYIELD_CREDENTIAL_DYJSESSION,
	DYNAMICYIELD_G_CREDENTIALS,
	DYNAMICYIELD_G_DATA,
	DYNAMICYIELD_G_STORED_DATA_ENTRY_EXISTS,
	DYNAMICYIELD_M_SET_CREDENTIALS,
	DYNAMICYIELD_M_SET_FETCHING,
	DYNAMICYIELD_M_SET_INITIALIZING,
	DYNAMICYIELD_M_SET_PATH,
	DYNAMICYIELD_M_STORE_DATA,
	DYNAMICYIELD_M_UPDATE_CHOICES,
	mapFn,
} from '~/@constants/store';
import { postJson } from '~/app-utils/http';
import { useDynamicyieldStore } from '~/@api/store/dynamicyieldApi';
import { useServerContextStore } from '~/@api/store/serverContextApi';

const DY_CHOOSE_API_ENDPOINT = 'https://direct.dy-api.eu/v2/serve/user/choose';
const DY_REPORT_API_ENDPOINT = 'https://direct-collect.dy-api.eu/v2/collect/user/engagement';

export enum MutationUpdateMode {
	reset,
	add,
	remove,
}

// Initial state -----------------------------------------------------------------------------------

const state = (): DynamicYieldState => ({
	isInitializing: false,
	credentials: {
		_dyid_server: undefined,
		_dyid: undefined,
		_dyjsession: undefined,
	},
	data: {},
	choices: {},
	fetching: [],
	path: '',
	config: {
		debugging: {
			active: false,
		},
	},
});

// Mutations ---------------------------------------------------------------------------------------

const mutations = {
	[mapFn(DYNAMICYIELD_M_SET_PATH)](_state: DynamicYieldState, path: string) {
		_state.path = path;
	},
	[mapFn(DYNAMICYIELD_M_SET_FETCHING)](_state: DynamicYieldState, fetching: string[]) {
		_state.fetching = fetching;
	},

	[mapFn(DYNAMICYIELD_M_SET_INITIALIZING)](_state: DynamicYieldState, isInitializing: boolean) {
		_state.isInitializing = isInitializing;
	},

	[mapFn(DYNAMICYIELD_M_SET_CREDENTIALS)](
		_state: DynamicYieldState,
		credentials: DynamicYieldCredentials,
	) {
		_state.credentials[DYNAMICYIELD_CREDENTIAL_DYID_SERVER] =
			credentials[DYNAMICYIELD_CREDENTIAL_DYID_SERVER];
		_state.credentials[DYNAMICYIELD_CREDENTIAL_DYID] = credentials[DYNAMICYIELD_CREDENTIAL_DYID];
		_state.credentials[DYNAMICYIELD_CREDENTIAL_DYJSESSION] =
			credentials[DYNAMICYIELD_CREDENTIAL_DYJSESSION];
	},

	[mapFn(DYNAMICYIELD_M_STORE_DATA)](
		_state: DynamicYieldState,
		payload: { id: string; data: any },
	) {
		if (!payload?.id) {
			dyLoggerDebug(_state.config.debugging, 'Data-ID is missing');

			return;
		}

		if (payload.data === MutationUpdateMode.remove) {
			delete _state.data[payload?.id];
		} else {
			_state.data = {
				...(_state.data || {}),
				[payload?.id]: payload?.data,
			};
		}
	},

	[mapFn(DYNAMICYIELD_M_UPDATE_CHOICES)](
		_state: DynamicYieldState,
		payload: {
			mode: MutationUpdateMode;
			choice?: DynamicYieldChoice;
			name?: string;
		},
	) {
		const { api: dynamicyieldApi } = useDynamicyieldStore(this);

		if (payload.mode === MutationUpdateMode.reset) {
			_state.choices = {};
		} else if (payload.mode === MutationUpdateMode.add) {
			_state.choices[dynamicyieldApi.buildDyDataKey(payload.choice.name)] = payload.choice;
		} else if (payload.mode === MutationUpdateMode.remove) {
			delete _state.choices[dynamicyieldApi.buildDyDataKey(payload.name || payload.choice.name)];
		}
	},
};

// Actions -----------------------------------------------------------------------------------------

const actions = {
	async [mapFn(DYNAMICYIELD_A_REPORT)](context, payload: object[]) {
		const credentials = getCredentials(context, this.$cookies);

		const dyData = {
			session: {
				dy: credentials[DYNAMICYIELD_CREDENTIAL_DYJSESSION],
			},
			user: {
				dyid: credentials[DYNAMICYIELD_CREDENTIAL_DYID],
				dyid_server: credentials[DYNAMICYIELD_CREDENTIAL_DYID_SERVER],
			},
			engagements: payload,
		};

		try {
			// Reports 204 (empty) if successful
			await postJson(
				DY_REPORT_API_ENDPOINT,
				dyData,
				this,
				{
					'DY-API-Key': useServerContextStore(this).state.session.dynamicYieldApiToken,
				},
				true,
				false,
			);
		} catch (error) {
			dyLoggerDebug(context.state.config.debugging, `Error reporting engagement: ${error}`);
		}
	},

	async [mapFn(DYNAMICYIELD_A_FETCH)](
		context: ActionContext<DynamicYieldState, RootState>,
		payload: DynamicYieldPayload,
	) {
		let selectors = [];

		if (typeof payload.selector === 'string') {
			const singleSelectorResult = await getChoiceOrSelector(context, this, payload);

			if (Array.isArray(singleSelectorResult)) {
				selectors = singleSelectorResult;
			} else {
				return singleSelectorResult;
			}
		} else if (Array.isArray(payload.selector)) {
			selectors = payload.selector.filter(
				(selector: string) =>
					!context.state.fetching.includes(selector) &&
					(!Object.keys(context.state.choices).includes(selector) || payload.forceUpdate),
			);
		}

		if (selectors.length) {
			try {
				context.commit(mapFn(DYNAMICYIELD_M_SET_FETCHING), [
					...context.state.fetching,
					...selectors,
				]);

				const contextInfo = {
					locationUrl: useServerContextStore(this).state.baseURL + context.state.path,
					userAgent: this.$ua.original(),
				};

				const { runTaskWithPromise } = await importRunTask();

				await runTaskWithPromise(() =>
					fetchDataFromDY(context, this, payload, this.$cookies, contextInfo, selectors),
				);
			} finally {
				context.commit(
					mapFn(DYNAMICYIELD_M_SET_FETCHING),
					context.state.fetching.filter((selector) => !selectors.includes(selector)),
				);
			}
		}

		if (typeof payload.selector === 'string') {
			dyLoggerDebug(
				context.state.config.debugging,
				`Return fetched choice for ${payload.selector}`,
			);

			return pickFetchedChoice(
				context,
				useDynamicyieldStore(this).api.buildDyDataKey(payload.selector),
			);
		}
	},
};

// Getters -----------------------------------------------------------------------------------------

const getters = {
	[mapFn(DYNAMICYIELD_G_CREDENTIALS)](_state: DynamicYieldState): DynamicYieldCredentials {
		return _state.credentials;
	},

	[mapFn(DYNAMICYIELD_G_DATA)](_state: DynamicYieldState) {
		return (id: string): DynamicYieldData => _state.data?.[id];
	},

	[mapFn(DYNAMICYIELD_G_STORED_DATA_ENTRY_EXISTS)](_state: DynamicYieldState) {
		return (id: string): boolean => id in _state.data;
	},
};

export default {
	state,
	mutations,
	actions,
	getters,
};

// Helpers -----------------------------------------------------------------------------------------

async function getChoiceOrSelector(
	context,
	store,
	payload,
): Promise<DynamicYieldChoice | string[]> {
	const choiceKey = useDynamicyieldStore(store).api.buildDyDataKey(payload.selector);

	if (choiceKey in context.state.choices && payload.forceUpdate) {
		await yieldToMain();

		context.commit(mapFn(DYNAMICYIELD_M_UPDATE_CHOICES), {
			mode: MutationUpdateMode.remove,
			name: payload.selector,
		});

		await yieldToMain();
	}

	if (context.state.fetching.includes(payload.selector) && waitForChoice) {
		const choice = await waitForChoice(choiceKey, context);

		if (choice) {
			return choice;
		}
	}

	return context.state.fetching.includes(payload.selector) ? [] : [payload.selector];
}

async function waitForChoice(
	choiceKey: string,
	context: ActionContext<DynamicYieldState, RootState>,
	totalTimeout = 3000,
	timeout = 20,
): Promise<DynamicYieldChoice> {
	if (choiceKey in context.state.choices) {
		dyLoggerDebug(context.state.config.debugging, `Return prefetched choice for ${choiceKey}`);
		return pickFetchedChoice(context, choiceKey);
	}
	if (totalTimeout <= 0) {
		dyLoggerDebug(
			context.state.config.debugging,
			`Won't wait any longer choice for ${choiceKey}, fetching it myself`,
		);
		return null;
	}
	dyLoggerDebug(context.state.config.debugging, `Awaiting prefetched choice for ${choiceKey}`);
	await new Promise((resolve) => setSafeTimeout(resolve, timeout));
	return waitForChoice(choiceKey, context, totalTimeout - timeout);
}

function pickFetchedChoice(
	context: ActionContext<DynamicYieldState, RootState>,
	selector: string,
): DynamicYieldChoice {
	dyLoggerDebug(context.state.config.debugging, `Return already fetched choice for ${selector}`);
	return context.state.choices[selector];
}

function getCredentials(store: Store<RootState>, cookies: NuxtCookies): DynamicYieldCredentials {
	const stateCredentials = useDynamicyieldStore(store).api.getCredentials();
	// Use cookie credentials if available, otherwise state credentials as fallback
	const credentialObject = {};

	[
		DYNAMICYIELD_CREDENTIAL_DYID,
		DYNAMICYIELD_CREDENTIAL_DYID_SERVER,
		DYNAMICYIELD_CREDENTIAL_DYJSESSION,
	].forEach((credentialKey: string) => {
		credentialObject[credentialKey] =
			cookies.get(credentialKey, { fromRes: true }) || stateCredentials[credentialKey];
	});

	return credentialObject as DynamicYieldCredentials;
}

async function fetchDataFromDY(
	context: ActionContext<DynamicYieldState, RootState>,
	store: Store<RootState>,
	payload: DynamicYieldPayload,
	cookies: NuxtCookies,
	contextInfo: { locationUrl: string; userAgent: any },
	requestedCampaigns: string[],
) {
	const { state: dynamicyieldState } = useDynamicyieldStore(store);
	const credentials = getCredentials(store, cookies); // Check if credentials already known

	dyLoggerDebug(
		dynamicyieldState.config.debugging,
		`Current credentials are ${DYNAMICYIELD_CREDENTIAL_DYID}: ${credentials[DYNAMICYIELD_CREDENTIAL_DYID]} and ${DYNAMICYIELD_CREDENTIAL_DYID_SERVER}: ${credentials[DYNAMICYIELD_CREDENTIAL_DYID_SERVER]}`,
	);

	if (
		!!credentials[DYNAMICYIELD_CREDENTIAL_DYID] ||
		!!credentials[DYNAMICYIELD_CREDENTIAL_DYID_SERVER]
	) {
		dyLoggerDebug(
			dynamicyieldState.config.debugging,
			`Credentials already known for ${requestedCampaigns.join(', ')}`,
		);
	} else if (!dynamicyieldState.isInitializing) {
		dyLoggerDebug(
			dynamicyieldState.config.debugging,
			`Credentials unknown when requested ${requestedCampaigns.join(', ')}`,
		);
		context.commit(mapFn(DYNAMICYIELD_M_SET_INITIALIZING), true);
	} else {
		// Not yet initialized but initialization is already ongoing -> wait for credentials to be known
		dyLoggerDebug(
			dynamicyieldState.config.debugging,
			`Request for fetching credentials currently running - putted ${requestedCampaigns.join(
				', ',
			)} into waiting queue until credentials are known`,
		);

		const requiredCredentialExist = await checkIfRequiredCrentialsExist(
			context,
			cookies,
			requestedCampaigns,
		);

		// If still not yet initzialized - abort this request
		if (!requiredCredentialExist) {
			dyLoggerDebug(
				dynamicyieldState.config.debugging,
				`Request for ${requestedCampaigns.join(', ')} aborted out of waiting queue due to timeout`,
			);
		}
	}

	const { state: serverContextState } = useServerContextStore(store);
	const dyApiKey = serverContextState.session.dynamicYieldApiToken;

	// Prevent mismatching _dyid and _dyid_server cookie values
	if (
		!!credentials[DYNAMICYIELD_CREDENTIAL_DYID] &&
		!!credentials[DYNAMICYIELD_CREDENTIAL_DYID_SERVER] &&
		credentials[DYNAMICYIELD_CREDENTIAL_DYID] !== credentials[DYNAMICYIELD_CREDENTIAL_DYID_SERVER]
	) {
		credentials[DYNAMICYIELD_CREDENTIAL_DYID] = undefined;
		dyLoggerDebug(
			dynamicyieldState.config.debugging,
			`Unmatching ${DYNAMICYIELD_CREDENTIAL_DYID} and ${DYNAMICYIELD_CREDENTIAL_DYID_SERVER} present - unsetted ${DYNAMICYIELD_CREDENTIAL_DYID}`,
		);
	}

	try {
		const request = async () => {
			const body = {
				context: {
					page: {
						location: contextInfo.locationUrl,
						...payload.pageContext,
					},
					device: {
						userAgent: contextInfo.userAgent,
						ip: serverContextState.session.clientIPAddress,
					},
					options: {
						isImplicitPageview: false,
					},
				},
				selector: {
					names: requestedCampaigns,
				},
				session: {
					dy:
						credentials[DYNAMICYIELD_CREDENTIAL_DYJSESSION] &&
						`${credentials[DYNAMICYIELD_CREDENTIAL_DYJSESSION]}`,
				},
				user: {
					dyid:
						credentials[DYNAMICYIELD_CREDENTIAL_DYID] &&
						`${credentials[DYNAMICYIELD_CREDENTIAL_DYID]}`,
					dyid_server:
						credentials[DYNAMICYIELD_CREDENTIAL_DYID_SERVER] && `${credentials._dyid_server}`,
				},
			};

			let response;

			try {
				response = await postJson(
					DY_CHOOSE_API_ENDPOINT,
					body,
					store,
					{ 'DY-API-Key': dyApiKey },
					false,
					false,
				);
			} catch (error) {
				dyLoggerDebug(
					dynamicyieldState.config.debugging,
					`Failed to request ${requestedCampaigns.join(', ')} in http.ts . Reason:`,
					error,
				);
			}

			return response?.json();
		};

		dyLoggerDebug(
			dynamicyieldState.config.debugging,
			`Request campaign(s) '${requestedCampaigns.join(
				`', '`,
			)}' with ${DYNAMICYIELD_CREDENTIAL_DYID}: ${
				credentials[DYNAMICYIELD_CREDENTIAL_DYID]
			} and ${DYNAMICYIELD_CREDENTIAL_DYID_SERVER}: ${
				credentials[DYNAMICYIELD_CREDENTIAL_DYID_SERVER]
			}`,
		);

		const response = await request();

		if (!response) {
			dyLoggerDebug(
				dynamicyieldState.config.debugging,
				`No response received for ${requestedCampaigns.join(`', '`)}`,
			);
			return Promise.resolve();
		}

		// When was an initializing run - set the credentials
		if (dynamicyieldState.isInitializing) {
			dyLoggerDebug(dynamicyieldState.config.debugging, 'Update credentials');
			updateCookieCredentials(context, store, response.cookies, cookies);
			updateStoreCredentials(context, store, response.cookies, cookies);
			context.commit(mapFn(DYNAMICYIELD_M_SET_INITIALIZING), false);
		}

		dyLoggerDebug(
			dynamicyieldState.config.debugging,
			`Response result(s) '${requestedCampaigns.join(
				`', '`,
			)}' with ${DYNAMICYIELD_CREDENTIAL_DYID}: ${
				credentials[DYNAMICYIELD_CREDENTIAL_DYID]
			} and ${DYNAMICYIELD_CREDENTIAL_DYID_SERVER}: ${
				credentials[DYNAMICYIELD_CREDENTIAL_DYID_SERVER]
			}`,
		);

		if (response.warnings?.length) {
			response.warnings.forEach((warning: { code: string; message: string }) => {
				dyLoggerDebug(context.state.config.debugging, warning.message);
			});
		}

		if (!response.choices) {
			dyLoggerDebug(
				dynamicyieldState.config.debugging,
				`No choices received for ${requestedCampaigns.join(`', '`)}`,
			);
			return Promise.resolve();
		}

		// Add received choices to store
		await Promise.all(
			response.choices.map(async (dyChoice: DynamicYieldChoice) => {
				dyChoice.variations?.forEach((choiceVariant, variantIndex) => {
					dyLoggerDebug(
						dynamicyieldState.config.debugging,
						`Add dyChoice for "${dyChoice.name}" (Variant ${variantIndex + 1}/${
							dyChoice.variations.length
						}) to store`,
						choiceVariant,
					);
				});

				await yieldToMain();

				context.commit(mapFn(DYNAMICYIELD_M_UPDATE_CHOICES), {
					mode: MutationUpdateMode.add,
					choice: dyChoice,
				});

				await yieldToMain();
			}),
		);
	} catch (error) {
		dyLoggerDebug(
			dynamicyieldState.config.debugging,
			`Could not fetch from DY for ${requestedCampaigns.join(', ')}. Reason:`,
			error,
		);
	}
}

function updateCookieCredentials(
	context: ActionContext<DynamicYieldState, RootState>,
	store: Store<RootState>,
	dyApiCookies: DynamicYieldCookie[],
	cookies: NuxtCookies,
) {
	const credentials = getCredentials(store, cookies);
	if (
		!credentials[DYNAMICYIELD_CREDENTIAL_DYID] ||
		!credentials[DYNAMICYIELD_CREDENTIAL_DYID_SERVER]
	) {
		try {
			// Set the cookies
			(dyApiCookies || []).forEach((cookie: any) => {
				// Important! Ensure to set this cookies only if not yet set
				// Never overwrite DY cookies when already there.
				if (!cookies.get(cookie.name, { fromRes: true })) {
					cookies.set(cookie.name, cookie.value, { maxAge: cookie.maxAge, path: '/' });
				}
			});
		} catch (error) {
			dyLoggerDebug(context.state.config.debugging, 'Could not update cookies', error);
		}
	}

	dyLoggerDebug(
		context.state.config.debugging,
		`updated credentials to ${DYNAMICYIELD_CREDENTIAL_DYID}: ${credentials[DYNAMICYIELD_CREDENTIAL_DYID]} and ${DYNAMICYIELD_CREDENTIAL_DYID_SERVER}: ${credentials[DYNAMICYIELD_CREDENTIAL_DYID_SERVER]}`,
	);
}

function updateStoreCredentials(
	context: ActionContext<DynamicYieldState, RootState>,
	store: Store<RootState>,
	dyApiCookies: DynamicYieldCookie[],
	cookies: NuxtCookies,
) {
	// update credentials in store
	try {
		const credentials = getCredentials(store, cookies);
		const updateCredential = (credentialName: string) =>
			credentials[credentialName] ||
			(dyApiCookies || []).find((cookie) => cookie.name === credentialName)?.value;

		updateCredential(DYNAMICYIELD_CREDENTIAL_DYID);
		updateCredential(DYNAMICYIELD_CREDENTIAL_DYID_SERVER);
		// Set cookie values as well for store
		context.commit(mapFn(DYNAMICYIELD_M_SET_CREDENTIALS), credentials);
	} catch (error) {
		dyLoggerDebug(context.state.config.debugging, 'Could update credentials', error);
	}
}

function dyLoggerDebug(debugging: DebugSettings, msg: string, obj: any | undefined = undefined) {
	if (!debugging.active) return;

	importLogger().then(({ default: Logger }) => {
		if (obj) {
			Logger.debug(`[DY] ${msg}`, obj);
		} else {
			Logger.debug(`[DY] ${msg}`);
		}
	});
}

function checkIfRequiredCrentialsExist(context, cookies, requestedCampaigns): Promise<boolean> {
	return new Promise((resolve) => {
		const recheckAfterMs = 100;
		const timeNow = new Date();
		const timeout = timeNow.setSeconds(timeNow.getSeconds() + 60);
		let checkTimeout;

		const checkForCredentials = () => {
			dyLoggerDebug(
				context.state.config.debugging,
				`Check if credentials already present for ${requestedCampaigns.join(',')}`,
			);

			const timeCurr = new Date().getTime();

			const credentials = getCredentials(context, cookies);

			const requiredCredentialExist =
				!!credentials[DYNAMICYIELD_CREDENTIAL_DYID] ||
				!!credentials[DYNAMICYIELD_CREDENTIAL_DYID_SERVER];

			// All credentials are present or reached timeout
			if (requiredCredentialExist || timeout >= timeCurr) {
				dyLoggerDebug(
					context.state.config.debugging,
					`Credentials exist or timeout for ${requestedCampaigns.join(',')}`,
				);

				clearTimeout(checkTimeout);
				resolve(requiredCredentialExist);
			} else {
				checkTimeout = setSafeTimeout(checkForCredentials, recheckAfterMs);
			}
		};

		checkTimeout = setSafeTimeout(checkForCredentials, recheckAfterMs);
	});
}
