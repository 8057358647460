/* eslint-disable */
// -----------------------------------------------------------
// v2/types/zipSuggestionResponse.json
// -----------------------------------------------------------

export interface ZipSuggestionResponse {
  requestedPrefix: string;
  suggestions?: ZipSuggestion[];
}

// -----------------------------------------------------------
// v2/types/zipSuggestion.json
// -----------------------------------------------------------

export interface ZipSuggestion {
  country?: Country;
  city: string;
  zip: string;
}

// -----------------------------------------------------------
// v2/types/voucher.json
// -----------------------------------------------------------

export interface Voucher {
  appliedValue?: ProductPrice;
  code: string;
  description?: string;
  freeShipping: boolean;
  name?: string;
  value?: number;
  valueFormatted?: string;
}

// -----------------------------------------------------------
// v2/types/videoType.json
// -----------------------------------------------------------

export type VideoType = "YOUTUBE" | "VIMEO";

// -----------------------------------------------------------
// v2/types/videoEntry.json
// -----------------------------------------------------------

export interface VideoEntry {
  videoType: VideoType;
  url: string;
}

// -----------------------------------------------------------
// v2/types/vendor.json
// -----------------------------------------------------------

export interface Vendor {
  code: string;
  external: boolean;
  name?: string;
  visibleInShop: boolean;
}

// -----------------------------------------------------------
// v2/types/validationErrorResponse.json
// -----------------------------------------------------------

export interface ValidationErrorResponse {
  errors?: FormError[];
  response?: unknown;
}

// -----------------------------------------------------------
// v2/types/userChangePasswordRequest.json
// -----------------------------------------------------------

export interface UserChangePasswordRequest {
  currentPassword: string;
  newPassword1: string;
  newPassword2: string;
}

// -----------------------------------------------------------
// v2/types/userChangeEmailRequest.json
// -----------------------------------------------------------

export interface UserChangeEmailRequest {
  currentEmail: string;
  newEmail: string;
  password: string;
}

// -----------------------------------------------------------
// v2/types/user.json
// -----------------------------------------------------------

export interface User {
  alreadyAskedClubInterest?: boolean;
  birthday?: string;
  ccMode: boolean;
  clubId?: string;
  customerNumber?: string;
  email?: string;
  gender?: Gender;
  genders?: Gender[];
  firstName?: string;
  lastName?: string;
  language?: Language;
  languages?: Language[];
  loggedIn: boolean;
  phone?: string;
  title?: Title;
  uid?: string;
}

// -----------------------------------------------------------
// v2/types/trusty.json
// -----------------------------------------------------------

export interface Trusty {
  iconSvg?: string;
  isClickable: boolean;
  showTopSeparator: boolean;
  text?: string;
  showOnMobile: boolean;
}

// -----------------------------------------------------------
// v2/types/trustiesResponse.json
// -----------------------------------------------------------

export interface TrustiesResponse {
  trusties: Trusty[];
}

// -----------------------------------------------------------
// v2/types/title.json
// -----------------------------------------------------------

export interface Title {
  code: "at" | "company" | "family" | "mr" | "mrs";
  name: string;
}

// -----------------------------------------------------------
// v2/types/timeOfDay.json
// -----------------------------------------------------------

export interface TimeOfDay {
  hour?: number;
  minute?: number;
}

// -----------------------------------------------------------
// v2/types/textSizeWrapper.json
// -----------------------------------------------------------

export interface TextSizeWrapper {
  desktopTextSize?:
    | "TEXT_XS"
    | "TEXT_SM"
    | "TEXT_BASE"
    | "TEXT_LG"
    | "TEXT_XL"
    | "TEXT_2XL"
    | "TEXT_3XL"
    | "TEXT_4XL"
    | "TEXT_5XL"
    | "TEXT_6XL"
    | "TEXT_7XL"
    | "TEXT_8XL"
    | "TEXT_9XL";
  mobileTextSize?:
    | "TEXT_XS"
    | "TEXT_SM"
    | "TEXT_BASE"
    | "TEXT_LG"
    | "TEXT_XL"
    | "TEXT_2XL"
    | "TEXT_3XL"
    | "TEXT_4XL"
    | "TEXT_5XL"
    | "TEXT_6XL"
    | "TEXT_7XL"
    | "TEXT_8XL"
    | "TEXT_9XL";
}

// -----------------------------------------------------------
// v2/types/textComponent.json
// -----------------------------------------------------------

export interface TextComponent {
  title?: string;
  teasingText: string;
  text: string;
  uid: string;
  trackingId?: string;
}

// -----------------------------------------------------------
// v2/types/text.json
// -----------------------------------------------------------

export interface Text {
  text: string;
  color?: "TEXT_WHITE" | "TEXT_BLACK";
  size?: TextSizeWrapper;
}

// -----------------------------------------------------------
// v2/types/testimonialTeaserComponent.json
// -----------------------------------------------------------

export interface TestimonialTeaserComponent {
  image: Image;
  name: string;
  text: string;
  uid: string;
  trackingId?: string;
}

// -----------------------------------------------------------
// v2/types/teaserDataList.json
// -----------------------------------------------------------

export interface TeaserDataList {
  uid: string;
  useSliderOnMobile?: boolean;
  datas?: GridLayoutData[];
}

// -----------------------------------------------------------
// v2/types/swissbillingPaymentInfo.json
// -----------------------------------------------------------

export interface SwissbillingPaymentInfo {
  termsAccepted?: boolean;
  installmentPreselected?: boolean;
  installmentDisabled?: boolean;
  installmentCompletelyDisabled?: boolean;
  enabled?: boolean;
}

// -----------------------------------------------------------
// v2/types/ssrInitializationResponse.json
// -----------------------------------------------------------

export interface SSRInitializationResponse {
  clsOptimizationsEnabled?: boolean;
  csrfToken?: string;
  debugEnabled?: boolean;
  dynamicYieldAsync?: boolean;
  dynamicYieldSectionId?: string;
  dynamicYieldApiToken?: string;
  googleTagManagerContainerId?: string;
  googleTagManagerEnabled?: boolean;
  trackingEnabled?: boolean;
  smartProxyEnabled?: boolean;
  globalMessages?: GlobalMessage[];
}

// -----------------------------------------------------------
// v2/types/ssrInitializationRequest.json
// -----------------------------------------------------------

export interface SSRInitializationRequest {
  location?: string;
}

// -----------------------------------------------------------
// v2/types/spaWrapperResponse.json
// -----------------------------------------------------------

export interface SpaWrapperResponse {
  digitalData?: SpaDigitalDatum;
  maintenance?: boolean;
  metaInformation?: SpaMetaInformationResponse;
  richSnippet?: unknown;
}




// -----------------------------------------------------------
// v2/types/spaType.json
// -----------------------------------------------------------

export enum SpaType {
  product = "product",
  category = "category",
  content = "content",
  other = "other"
}

// -----------------------------------------------------------
// v2/types/spaRichSnippetWebsite.json
// -----------------------------------------------------------

export interface SpaRichSnippetWebsite {
  "@context"?: string;
  "@type"?: string;
  url?: string;
  potentialAction?: SpaRichSnippetSearchAction;
}

// -----------------------------------------------------------
// v2/types/spaRichSnippetSearchAction.json
// -----------------------------------------------------------

export interface SpaRichSnippetSearchAction {
  "@type"?: string;
  target?: string;
  "query-input"?: string;
}

// -----------------------------------------------------------
// v2/types/spaRichSnippetProductReviewAuthor.json
// -----------------------------------------------------------

export interface SpaRichSnippetProductAuthor {
  "@type"?: string;
  name?: string;
}

// -----------------------------------------------------------
// v2/types/spaRichSnippetProductReview.json
// -----------------------------------------------------------

export interface SpaRichSnippetProductReview {
  "@type"?: string;
  author?: SpaRichSnippetProductAuthor;
  datePublished?: string;
  description?: string;
  name?: string;
  reviewRating?: SpaRichSnippetProductRating;
}

// -----------------------------------------------------------
// v2/types/spaRichSnippetProductRating.json
// -----------------------------------------------------------

export interface SpaRichSnippetProductRating {
  "@type"?: string;
  ratingValue?: string;
  ratingCount?: string;
  bestRating?: string;
  worstRating?: string;
}

// -----------------------------------------------------------
// v2/types/spaRichSnippetProductOfferSeller.json
// -----------------------------------------------------------

export interface SpaRichSnippetProductOfferSeller {
  "@type"?: string;
  name?: string;
}

// -----------------------------------------------------------
// v2/types/spaRichSnippetProductOffer.json
// -----------------------------------------------------------

export interface SpaRichSnippetProductOffer {
  "@type"?: string;
  priceCurrency?: string;
  price?: string;
  priceValidUntil?: string;
  availability?: string;
  seller?: SpaRichSnippetProductOfferSeller;
  url?: string;
}

// -----------------------------------------------------------
// v2/types/spaRichSnippetProductBrand.json
// -----------------------------------------------------------

export interface SpaRichSnippetProductBrand {
  "@type"?: string;
  name?: string;
}

// -----------------------------------------------------------
// v2/types/spaRichSnippetProduct.json
// -----------------------------------------------------------

export interface SpaRichSnippetProduct {
  "@context"?: string;
  "@type"?: string;
  name?: string;
  image?: string;
  description?: string;
  mpn?: string;
  gtin13?: string;
  sku?: string;
  brand?: SpaRichSnippetProductBrand;
  offers?: SpaRichSnippetProductOffer;
  aggregateRating?: SpaRichSnippetProductRating;
  review?: SpaRichSnippetProductReview[];
}

// -----------------------------------------------------------
// v2/types/spaRichSnippetBreadcrumbListItem.json
// -----------------------------------------------------------

export interface SpaRichSnippetBreadcrumbListItem {
  "@type"?: string;
  position?: number;
  name?: string;
  item?: string;
}

// -----------------------------------------------------------
// v2/types/spaRichSnippetBreadcrumb.json
// -----------------------------------------------------------

export interface SpaRichSnippetBreadcrumb {
  "@context"?: string;
  "@type"?: string;
  itemListElement?: SpaRichSnippetBreadcrumbListItem[];
}

// -----------------------------------------------------------
// v2/types/spaRichSnippetBrand.json
// -----------------------------------------------------------

export interface SpaRichSnippetBrand {
  "@type"?: string;
  name?: string;
}

// -----------------------------------------------------------
// v2/types/spaOtherId.json
// -----------------------------------------------------------

export enum SpaOtherId {
  bike_finder = "bike_finder",
  cart = "cart",
  checkout_login = "checkout_login",
  checkout_delivery_address = "checkout_delivery_address",
  checkout_delivery_clickandcollect = "checkout_delivery_clickandcollect",
  checkout_delivery_billing = "checkout_delivery_billing",
  checkout_delivery_assembly = "checkout_delivery_assembly",
  checkout_orderconfirmation = "checkout_orderconfirmation",
  checkout_payment = "checkout_payment",
  checkout_summary = "checkout_summary",
  club_info = "club_info",
  club_pos = "club_pos",
  club_registration = "club_registration",
  club_sms = "club_sms",
  login = "login",
  myAccount = "myAccount",
  newsletter = "newsletter",
  newsletter_global_unsubscribe = "newsletter_global_unsubscribe",
  newsletter_unsubscribe_club = "newsletter_unsubscribe_club",
  newsletter_unsubscribe = "newsletter_unsubscribe",
  password_reset = "password_reset",
  search = "search",
  searchEmpty = "searchEmpty",
  shops = "shops",
  shops_NEUTRAL = "shops_NEUTRAL",
  shops_MALE = "shops_MALE",
  shops_FEMALE = "shops_FEMALE",
  shops_CHILD = "shops_CHILD",
  store_detail = "store_detail",
  store_finder = "store_finder",
  unknown = "unknown"
}

// -----------------------------------------------------------
// v2/types/spaMetaInformationResponse.json
// -----------------------------------------------------------

export interface SpaMetaInformationResponse {
  links?: SpaLink[];
  metaInformations?: SpaMetaInformation[];
  pageId?: string;
  schemaOrgType?: string;
  title?: string;
}




// -----------------------------------------------------------
// v2/types/spaMetaInformation.json
// -----------------------------------------------------------

export interface SpaMetaInformation {
  charset?: string;
  content?: string;
  httpEquiv?: string;
  itemprop?: string;
  id?: string;
  name?: string;
  property?: string;
}

// -----------------------------------------------------------
// v2/types/spaLinkRel.json
// -----------------------------------------------------------

export enum SpaLinkRel {
  alternate = "alternate",
  appleTouchIcon = "apple-touch-icon",
  appleTouchStartupImage = "apple-touch-startup-image",
  author = "author",
  canonical = "canonical",
  dnsPrefetch = "dns-prefetch",
  help = "help",
  icon = "icon",
  license = "license",
  manifest = "manifest",
  next = "next",
  pingback = "pingback",
  preconnect = "preconnect",
  prefetch = "prefetch",
  preload = "preload",
  prerender = "prerender",
  prev = "prev",
  search = "search",
  stylesheet = "stylesheet"
}

// -----------------------------------------------------------
// v2/types/spaLinkCrossorigin.json
// -----------------------------------------------------------

export enum SpaLinkCrossorigin {
  anonymous = "anonymous",
  useCredentials = "useCredentials"
}

// -----------------------------------------------------------
// v2/types/spaLink.json
// -----------------------------------------------------------

export interface SpaLink {
  as?: string;
  crossOrigin?: SpaLinkCrossorigin;
  href?: string;
  hreflang?: string;
  imagesrcset?: string;
  imagesizes?: string;
  media?: string;
  rel?: SpaLinkRel;
  sizes?: string;
  type?: string;
}




// -----------------------------------------------------------
// v2/types/spaDigitalDatum.json
// -----------------------------------------------------------

export interface SpaDigitalDatum {
  cart?: SpaDigitalDataCart;
  component?: SpaDigitalDataComponent;
  event?: SpaDigitalDataEvent;
  page?: SpaDigitalDataPage;
  pageInstanceID?: string;
  privacy?: SpaDigitalDataPrivacy;
  product?: SpaDigitalDataProduct;
  security?: string;
  transaction?: SpaDigitalDataTransaction;
  user?: SpaDigitalDataUser;
  version?: string;
}

// -----------------------------------------------------------
// v2/types/spaDigitalDataUserProfileSocial.json
// -----------------------------------------------------------

export interface SpaDigitalDataUserProfileSocial {
  facebook?: string;
  facebookInfo?: string;
  twitter?: string;
  twitterInfo?: string;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/spaDigitalDataUserProfileProfileInfo.json
// -----------------------------------------------------------

export interface SpaDigitalDataUserProfileProfileInfo {
  askedForClubMembership?: boolean;
  averageItemsPerOrder?: number;
  averageOrderValue?: number;
  email?: string;
  firstBuyDate?: string;
  language?: string;
  lastBuyDate?: string;
  lifetimeValue?: number;
  md5email?: string;
  ordersPerYear?: number;
  profileID?: string;
  returningStatus?: string;
  sha256email?: string;
  totalOrders?: number;
  type?: string;
  userName?: string;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/spaDigitalDataUserProfileAttributes.json
// -----------------------------------------------------------

export interface SpaDigitalDataUserProfileAttributes {
  adBlocker?: string;
  birthday?: string;
  clubstatus?: string;
  company?: string;
  customerID?: string;
  favouriteSports?: string[];
  gender?: string;
  isCustomer?: string;
  isDeleted?: string;
  employeePersonnelID?: string;
  isGuest?: string;
  isLocked?: string;
  isLoggedIn?: string;
  isNew?: string;
  isRegistered?: string;
  missingRevenue?: string;
  mobilePhone?: string;
  md5mobilePhone?: string;
  sha256mobilePhone?: string;
  phone?: string;
  md5phone?: string;
  sha256phone?: string;
  receivesNewsletter?: string;
  securityAffinity?: string;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/spaDigitalDataUserProfileAddress.json
// -----------------------------------------------------------

export interface SpaDigitalDataUserProfileAddress {
  city?: string;
  md5city?: string;
  sha256city?: string;
  country?: string;
  md5country?: string;
  sha256country?: string;
  line1?: string;
  md5line1?: string;
  sha256line1?: string;
  line2?: string;
  md5line2?: string;
  sha256line2?: string;
  postalCode?: string;
  md5postalCode?: string;
  sha256postalCode?: string;
  stateProvince?: string;
  md5stateProvince?: string;
  sha256stateProvince?: string;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/spaDigitalDataUserProfile.json
// -----------------------------------------------------------

export interface SpaDigitalDataUserProfile {
  address?: SpaDigitalDataUserProfileAddress;
  attributes?: SpaDigitalDataUserProfileAttributes;
  profileInfo?: SpaDigitalDataUserProfileProfileInfo;
  social?: SpaDigitalDataUserProfileSocial;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/spaDigitalDataUser.json
// -----------------------------------------------------------

export interface SpaDigitalDataUser {
  profile?: SpaDigitalDataUserProfile;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/spaDigitalDataTransactionProfileProfileInfo.json
// -----------------------------------------------------------

export interface SpaDigitalDataTransactionProfileProfileInfo {
  email?: string;
  md5email?: string;
  sha256email?: string;
  profileID?: string;
  userIsGuest?: string;
  userName?: string;
  md5userName?: string;
  sha256userName?: string;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/spaDigitalDataTransactionProfile.json
// -----------------------------------------------------------

export interface SpaDigitalDataTransactionProfile {
  address?: SpaDigitalDataUserProfileAddress;
  profileInfo?: SpaDigitalDataTransactionProfileProfileInfo;
  shippingAddress?: SpaDigitalDataUserProfileAddress;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/spaDigitalDataTransactionAttributes.json
// -----------------------------------------------------------

export interface SpaDigitalDataTransactionAttributes {
  deliveryFee?: string;
  deliveryMethod?: string;
  paymentFee?: string;
  paymentMethod?: string;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/spaDigitalDataTransaction.json
// -----------------------------------------------------------

export interface SpaDigitalDataTransaction {
  attributes?: SpaDigitalDataTransactionAttributes;
  item?: SpaDigitalDataCartItem[];
  profile?: SpaDigitalDataTransactionProfile;
  total?: SpaDigitalDataCartPrice;
  transactionID?: string;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/spaDigitalDataProductProductInfoSizingSystems.json
// -----------------------------------------------------------

export interface SpaDigitalDataProductProductInfoSizingSystems {
  sizingSystem?: string;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/spaDigitalDataProductProductInfoDescription.json
// -----------------------------------------------------------

export interface SpaDigitalDataProductProductInfoDescription {
  chars?: string;
  description?: string;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/spaDigitalDataProductProductInfo.json
// -----------------------------------------------------------

export interface SpaDigitalDataProductProductInfo {
  defaultCategoryId?: string;
  defaultCategoryName?: string;
  description?: SpaDigitalDataProductProductInfoDescription;
  jerseyPrint?: string;
  manufacturer?: string;
  playerName?: string;
  playerNumber?: string;
  playerPrint?: string;
  productID?: string;
  productImage?: string;
  productMasterNr?: string;
  productName?: string;
  productNr?: string;
  productThumbnail?: string;
  productURL?: string;
  size?: string;
  sizingSystems?: SpaDigitalDataProductProductInfoSizingSystems[];
  stock?: string;
  supplier?: string;
  teamName?: string;
  variantStockSum?: string;
  productHighlights?: string;
  hazardAndSafety?: string;
  productFeatures?: Feature[];
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/spaDigitalDataProductOption.json
// -----------------------------------------------------------

export interface SpaDigitalDataProductOption {
  code?: string;
  stockAmount?: number;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/spaDigitalDataProductCategoryCategory.json
// -----------------------------------------------------------

export interface SpaDigitalDataProductCategoryCategory {
  code?: string;
  name?: string;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/spaDigitalDataProductCategory.json
// -----------------------------------------------------------

export interface SpaDigitalDataProductCategory {
  currentProductCategory?: SpaDigitalDataProductCategoryCategory;
  parentProductCategory?: SpaDigitalDataProductCategoryCategory;
  productCategoryI?: string;
  productCategoryII?: string;
  productCategoryIII?: string;
  productCategoryIV?: string;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/spaDigitalDataProductAttributesSeason.json
// -----------------------------------------------------------

export interface SpaDigitalDataProductAttributesSeason {
  season?: string;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/spaDigitalDataProductAttributesRating.json
// -----------------------------------------------------------

export interface SpaDigitalDataProductAttributesRating {
  average?: string;
  count?: string;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/spaDigitalDataProductAttributes.json
// -----------------------------------------------------------

export interface SpaDigitalDataProductAttributes {
  colour?: string;
  currency?: string;
  ean?: string;
  expiryDate?: string;
  gender?: string;
  guarantee?: string;
  hasVideo?: boolean;
  isConfigurableItem?: string;
  isHeavyItem?: number;
  labels?: string[];
  manufacturerAID?: string;
  onlineDate?: string;
  originalPrice?: string;
  price?: string;
  priceDifference?: string;
  priceDifferencePercent?: string;
  rating?: SpaDigitalDataProductAttributesRating;
  seasons?: SpaDigitalDataProductAttributesSeason[];
  weight?: string;
  year?: string;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/spaDigitalDataProduct.json
// -----------------------------------------------------------

export interface SpaDigitalDataProduct {
  attributes?: SpaDigitalDataProductAttributes;
  category?: SpaDigitalDataProductCategory;
  linkedProduct?: SpaDigitalDataProduct[];
  options?: SpaDigitalDataProductOption[];
  productInfo?: SpaDigitalDataProductProductInfo;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/spaDigitalDataPrivacyPrivacyAccessCategories.json
// -----------------------------------------------------------

export interface SpaDigitalDataPrivacyPrivacyAccessCategories {
  categoryName?: string;
  domains?: string;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/spaDigitalDataPrivacyPrivacy.json
// -----------------------------------------------------------

export interface SpaDigitalDataPrivacyPrivacy {
  accessCategories?: SpaDigitalDataPrivacyPrivacyAccessCategories;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/spaDigitalDataPrivacy.json
// -----------------------------------------------------------

export interface SpaDigitalDataPrivacy {
  privacy?: SpaDigitalDataPrivacyPrivacy;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/spaDigitalDataPagePageInfo.json
// -----------------------------------------------------------

export interface SpaDigitalDataPagePageInfo {
  author?: string;
  breadCrumbs?: string[];
  destinationURL?: string;
  effectiveDate?: string;
  expiryDate?: string;
  geoRegion?: string;
  industryCodes?: string;
  issueDate?: string;
  language?: string;
  pageID?: string;
  pageName?: string;
  publisher?: string;
  referringURL?: string;
  sysEnv?: string;
  variant?: string;
  version?: string;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/spaDigitalDataPageCategory.json
// -----------------------------------------------------------

export interface SpaDigitalDataPageCategory {
  categoryID?: string;
  categoryName?: string;
  pageCategoryI?: string;
  pageCategoryII?: string;
  pageCategoryIII?: string;
  pageCategoryIV?: string;
  pageCategoryV?: string;
  pageCategoryVI?: string;
  pageCategoryVII?: string;
  pageType?: string;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/spaDigitalDataPageAttributes.json
// -----------------------------------------------------------

export interface SpaDigitalDataPageAttributes {
  delayType?: string;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/spaDigitalDataPage.json
// -----------------------------------------------------------

export interface SpaDigitalDataPage {
  attributes?: SpaDigitalDataPageAttributes;
  category?: SpaDigitalDataPageCategory;
  pageInfo?: SpaDigitalDataPagePageInfo;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/spaDigitalDataEventEventInfo.json
// -----------------------------------------------------------

export interface SpaDigitalDataEventEventInfo {
  effect?: string;
  eventAction?: string;
  eventName?: string;
  eventPoints?: string;
  timeStamp?: string;
  type?: string;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/spaDigitalDataEvent.json
// -----------------------------------------------------------

export interface SpaDigitalDataEvent {
  attributes?: string;
  category?: string;
  eventInfo?: SpaDigitalDataEventEventInfo;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/spaDigitalDataComponentComponentInfo.json
// -----------------------------------------------------------

export interface SpaDigitalDataComponentComponentInfo {
  componentID?: string;
  componentName?: string;
  description?: string;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/spaDigitalDataComponent.json
// -----------------------------------------------------------

export interface SpaDigitalDataComponent {
  category?: string;
  componentInfo?: SpaDigitalDataComponentComponentInfo;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/spaDigitalDataCartPriceAppliedVoucher.json
// -----------------------------------------------------------

export interface SpaDigitalDataCartPriceAppliedVoucher {
  type?: "voucher" | "giftcard";
  voucherCode?: string;
  voucherDiscount?: string;
  voucherName?: string;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/spaDigitalDataCartPrice.json
// -----------------------------------------------------------

export interface SpaDigitalDataCartPrice {
  appliedVouchers?: SpaDigitalDataCartPriceAppliedVoucher[];
  basePrice?: string;
  cartTotal?: string;
  clubDiscount?: string;
  currency?: string;
  priceWithTax?: string;
  shipping?: string;
  shippingMethod?: string;
  taxRate?: string;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/spaDigitalDataCartItemOptions.json
// -----------------------------------------------------------

export interface SpaDigitalDataCartItemOptions {
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/spaDigitalDataCartItemCategory.json
// -----------------------------------------------------------

export interface SpaDigitalDataCartItemCategory {
  pageCategoryI?: string;
  pageCategoryII?: string;
  pageCategoryIII?: string;
  pageCategoryIV?: string;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/spaDigitalDataCartItem.json
// -----------------------------------------------------------

export interface SpaDigitalDataCartItem {
  attributes?: SpaDigitalDataProductAttributes;
  category?: SpaDigitalDataCartItemCategory;
  linkedProducts?: string;
  options?: SpaDigitalDataProductOption[];
  price?: string;
  productInfo?: SpaDigitalDataProductProductInfo;
  quantity?: string;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/spaDigitalDataCartAttributes.json
// -----------------------------------------------------------

export interface SpaDigitalDataCartAttributes {
  numberOfProducts?: string;
  paymentMethod?: string;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/spaDigitalDataCart.json
// -----------------------------------------------------------

export interface SpaDigitalDataCart {
  attributes?: SpaDigitalDataCartAttributes;
  item?: SpaDigitalDataCartItem[];
  price?: SpaDigitalDataCartPrice;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/sliderTeaserData.json
// -----------------------------------------------------------

export interface SliderTeaserData {
  uid: string;
  datas?: GridLayoutData[];
  visibleDatasDesktop?: number;
  visibleDatasTablet?: number;
  visibleDatasMobile?: number;
}

// -----------------------------------------------------------
// v2/types/size.json
// -----------------------------------------------------------

export interface Size {
  defaultSizingSystem?: boolean;
  sizingSystemCode: string;
  sizingSystemName?: string;
  value: string;
}

// -----------------------------------------------------------
// v2/types/setPaymentModeRequest.json
// -----------------------------------------------------------

export interface SetPaymentModeRequest {
  paymentModeCode: string;
  swissbillingTermsAccepted?: boolean;
  swissbillingInstallmentSelected?: boolean;
}

// -----------------------------------------------------------
// v2/types/setPaymentInfoRequest.json
// -----------------------------------------------------------

export interface SetPaymentInfoRequest {
  paymentInfo: PaymentInfo;
  verificationCode?: string;
}

// -----------------------------------------------------------
// v2/types/serviceProductPrice.json
// -----------------------------------------------------------

export interface ServiceProductPrice {
  crossPriceTotalValue?: number;
  crossPriceTotalFormattedValue?: string;
  currencyIso: string;
  formattedValue: string;
  servicePriceType: "CUSTOM" | "DEFAULT";
  totalFormattedValue: string;
  totalValue: number;
  value: number;
}

// -----------------------------------------------------------
// v2/types/serversettingTracking.json
// -----------------------------------------------------------

export interface ServersettingTracking {
  impressionViewIntersectionTimeout?: number;
}

// -----------------------------------------------------------
// v2/types/serversettingStorefinderMarker.json
// -----------------------------------------------------------

export interface ServersettingStorefinderMarker {
  url?: string;
  size?: string;
  scaledSize?: string;
  origin?: string;
  anchor?: string;
}

// -----------------------------------------------------------
// v2/types/serversettingStorefinderClusterer.json
// -----------------------------------------------------------

export interface ServersettingStorefinderClusterer {
  imagePath?: string;
}

// -----------------------------------------------------------
// v2/types/serversettingStorefinder.json
// -----------------------------------------------------------

export interface ServersettingStorefinder {
  marker?: ServersettingStorefinderMarker;
  clusterer?: ServersettingStorefinderClusterer;
}

// -----------------------------------------------------------
// v2/types/serversettingPayments.json
// -----------------------------------------------------------

export interface ServersettingPayments {
  showSwissbillingInstallmentTotalFee: boolean;
}

// -----------------------------------------------------------
// v2/types/serversettingIntersectingComponentConfig.json
// -----------------------------------------------------------

export interface ServersettingIntersectingComponentConfig {
  disabled?: boolean;
  dummyStyle?: string;
  id?: string;
  onScroll?: boolean;
  rootMargin?: string;
  threshold?: number;
  timeout?: number;
}

// -----------------------------------------------------------
// v2/types/serversettingGoogleApi.json
// -----------------------------------------------------------

export interface ServersettingGoogleApi {
  key: string;
  version: string;
}

// -----------------------------------------------------------
// v2/types/serversettingGiftCard.json
// -----------------------------------------------------------

export interface ServersettingGiftCard {
  cardIdPrefix?: string[];
}

// -----------------------------------------------------------
// v2/types/serversettingCookieLaw.json
// -----------------------------------------------------------

export interface ServersettingCookieLaw {
  maxAge: number;
  position: string;
}

// -----------------------------------------------------------
// v2/types/serversetting.json
// -----------------------------------------------------------

export interface Serversetting {
  baseStore?: string;
  cmsSite?: string;
  commonResourcePath?: string;
  cookieLaw?: ServersettingCookieLaw;
  clubRegistrationTimer?: number;
  formValidationDisabled?: boolean;
  giftcard?: ServersettingGiftCard;
  googleApi?: ServersettingGoogleApi;
  intersectingComponents?: ServersettingIntersectingComponentConfig[];
  isRecaptchaDisabled?: boolean;
  paymentModes?: PaymentMode[];
  promotionTypes?: PromotionTypeSettings[];
  recaptchaKey?: string;
  scene7ImageUrl?: string;
  services?: ("FREE_SHIPPING" | "EXPRESS_DELIVERY" | "INVOICE" | "HOTLINE")[];
  showClubPriceInfoInMiniCart?: boolean;
  storefinder?: ServersettingStorefinder;
  storestockEnabled?: boolean;
  tracking?: ServersettingTracking;
  useNewOspLogo?: boolean;
  payments?: ServersettingPayments;
}

// -----------------------------------------------------------
// v2/types/sectionTitleComponent.json
// -----------------------------------------------------------

export interface SectionTitleComponent {
  title?: string;
  uid: string;
}

// -----------------------------------------------------------
// v2/types/searchSorts.json
// -----------------------------------------------------------

export interface SearchSorts {
  options?: SearchSort[];
  selectedOption: string;
}

// -----------------------------------------------------------
// v2/types/searchSort.json
// -----------------------------------------------------------

export interface SearchSort {
  code: string;
  name: string;
}

// -----------------------------------------------------------
// v2/types/searchResult.json
// -----------------------------------------------------------

export interface SearchResult {
  origPos: number;
  pos?: number;
  product: Product;
}

// -----------------------------------------------------------
// v2/types/searchRequestResponse.json
// -----------------------------------------------------------

export interface SearchRequestResponse {
  brandCategoryCode?: string;
  breadcrumbs?: Breadcrumb[];
  campaignRedirectDestination?: string;
  categoryCode?: string;
  categorieInfoText?: string;
  categorieInfoHeadline?: string;
  categorieInfoImage?: string;
  categorieInfoPosition?: "left" | "top";
  categoryName?: string;
  categoryHeadline?: string;
  currentNavigation?: CurrentNavigation;
  didYouMean?: string;
  facets?: SearchFacet[];
  metaInformationContainer?: MetaInformationContainer;
  pagination?: SearchPagination;
  productQuery?: string[];
  results?: SearchResult[];
  selectedGender?: string;
  sorts?: SearchSorts;
  suggestedSearchGendersLinks?: MapEntry[];
  campaign?: Campaign[];
  text?: string;
  showSwissBillingMessage?: boolean;
}

// -----------------------------------------------------------
// v2/types/searchPagination.json
// -----------------------------------------------------------

export interface SearchPagination {
  count: number;
  options?: number[];
  page: number;
  selectedOption: number;
  totalCount: number;
}

// -----------------------------------------------------------
// v2/types/searchFacetValuePrice.json
// -----------------------------------------------------------

export interface SearchFacetValuePrice {
  code: string;
  name?: string;
  selected: boolean;
  absoluteMax: number;
  absoluteMin: number;
  selectedMax: number;
  selectedMin: number;
  formattedSelectedMax?: string;
  formattedSelectedMin?: string;
}

// -----------------------------------------------------------
// v2/types/searchFacetValueDefault.json
// -----------------------------------------------------------

export interface SearchFacetValueDefault {
  code: string;
  name: string;
  count: number;
  selected?: boolean;
}

// -----------------------------------------------------------
// v2/types/searchFacetValueColor.json
// -----------------------------------------------------------

export interface SearchFacetValueColor {
  code: string;
  color: string;
  count: number;
  name: string;
  selected?: boolean;
}

// -----------------------------------------------------------
// v2/types/searchFacetValueCategories.json
// -----------------------------------------------------------

export interface SearchFacetValueCategories {
  code: string;
  count: number;
  name: string;
  selected?: boolean;
  index?: boolean;
  hideInNavigation?: boolean;
  subCategories?: SearchFacetValueCategories[];
}

// -----------------------------------------------------------
// v2/types/searchFacet.json
// -----------------------------------------------------------

export interface SearchFacet {
  code: string;
  name: string;
  values?: unknown[];
}

// -----------------------------------------------------------
// v2/types/remainingValueGiftCards.json
// -----------------------------------------------------------

export interface RemainingValueGiftCards {
  code?: string;
  remainingValue?: number;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/registrationResponse.json
// -----------------------------------------------------------

export interface RegistrationResponse {
  /**
   * If the request was not successful, this flag indicates if the next one needs a captcha.
   */
  showCaptcha?: boolean;
}

// -----------------------------------------------------------
// v2/types/registrationRequest.json
// -----------------------------------------------------------

export interface RegistrationRequest {
  captcha?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  registerForNewstletter?: boolean;
  password?: string;
  termsAndConditionsChecked?: boolean;
  title?: Title;
}

// -----------------------------------------------------------
// v2/types/registrationInformation.json
// -----------------------------------------------------------

export interface RegistrationInformation {
  showTermsAndConditions?: boolean;
  showCaptcha?: boolean;
  trusties?: Trusty[];
}

// -----------------------------------------------------------
// v2/types/redirectInfo.json
// -----------------------------------------------------------

export interface RedirectInfo {
  location: string;
}

// -----------------------------------------------------------
// v2/types/recommendationsResponse.json
// -----------------------------------------------------------

export interface RecommendationsResponse {
  productCarousels?: ProductCarousel[];
}

// -----------------------------------------------------------
// v2/types/promotionTypeSettings.json
// -----------------------------------------------------------

export interface PromotionTypeSettings {
  code?: string;
  color?: "black" | "blue" | "green" | "yellow" | "red" | "grey";
  hiddenAtSpots?: ("CART" | "MINICART" | "MEGAFLYOUT")[];
  label?: string;
  position?: "TOP" | "RIGHT" | "BOTTOM" | "LEFT";
}

// -----------------------------------------------------------
// v2/types/promotionTextData.json
// -----------------------------------------------------------

export interface PromotionTextData {
  uid: string;
  text?: string;
  title?: string;
  legalText?: string;
}

// -----------------------------------------------------------
// v2/types/promotionTeaserComponent.json
// -----------------------------------------------------------

export interface PromotionTeaserComponent {
  backgroundColor?: string;
  button: Button;
  images: CmsImagesWrapper;
  headline?: Text;
  preHeadline?: Text;
  subline?: Text;
  uid: string;
  variant?: "SMALL" | "BIG";
  trackingId?: string;
}

// -----------------------------------------------------------
// v2/types/productStock.json
// -----------------------------------------------------------

export interface ProductStock {
  available: number;
  status: "INSTOCK" | "LOWSTOCK" | "OUTOFSTOCK";
}

// -----------------------------------------------------------
// v2/types/productReviews.json
// -----------------------------------------------------------

export interface ProductReviews {
  average?: number;
  count?: number;
}

// -----------------------------------------------------------
// v2/types/productRedirect.json
// -----------------------------------------------------------

export interface ProductRedirect {
  targetUrl?: string;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/productPrice.json
// -----------------------------------------------------------

export interface ProductPrice {
  currencyIso: string;
  formattedValue: string;
  value: number;
  showInstallment?: boolean;
  origin?: "SELF_COMPARISON" | "INTRODUCTORY_PRICE" | "COMPETITORS_COMPARISON";
}

// -----------------------------------------------------------
// v2/types/productList.json
// -----------------------------------------------------------

export interface ProductList {
  products?: Product[];
}

// -----------------------------------------------------------
// v2/types/productLabel.json
// -----------------------------------------------------------

export interface ProductLabel {
  label: string;
  code?: string;
  color: "black" | "blue" | "green" | "yellow" | "red" | "grey";
  position?: "TOP" | "RIGHT" | "BOTTOM" | "LEFT";
}

// -----------------------------------------------------------
// v2/types/productColorVariant.json
// -----------------------------------------------------------

export interface ProductColorVariant {
  code?: string;
  color?: ProductColor;
  dsaProduct?: boolean;
  ean?: string;
  image?: Image;
  shoeSizeType?: "NOT_SHOE" | "EU_SHOE" | "NON_EU_SHOE";
  sizeVariants?: ProductColorSizeVariant[];
  stock?: ProductStock;
  url?: string;
}

// -----------------------------------------------------------
// v2/types/productColorSizeVariant.json
// -----------------------------------------------------------

export interface ProductColorSizeVariant {
  code?: string;
  dsaProduct?: boolean;
  ean?: string;
  stock?: ProductStock;
  systems?: Size[];
  url?: string;
  visible?: boolean;
}

// -----------------------------------------------------------
// v2/types/productColorRange.json
// -----------------------------------------------------------

export interface ProductColorRange {
  code: string;
  name: string;
}

// -----------------------------------------------------------
// v2/types/productColor.json
// -----------------------------------------------------------

export interface ProductColor {
  code: string;
  name: string;
}

// -----------------------------------------------------------
// v2/types/productCarousel.json
// -----------------------------------------------------------

export interface ProductCarousel {
  products?: Product[];
  title?: string;
  subTitle?: string;
  button?: Button;
  trackingId?: string;
}

// -----------------------------------------------------------
// v2/types/product.json
// -----------------------------------------------------------

export interface Product {
  articleCode?: string;
  brand?: Brand;
  breadcrumbs?: Breadcrumb[];
  bulky?: boolean;
  classifications?: Classification[];
  clubDiscount?: ProductPrice;
  code: string;
  color?: ProductColor;
  colorRanges?: ProductColorRange[];
  colorVariants?: ProductColorVariant[];
  deliveryInformation?: DeliveryInformation;
  deliveryModes?: DeliveryMode[];
  deliveryTimes?: MapObjectEntry[];
  showDeliveryTimeCounter?: boolean;
  countdownTimeCounterTo?: {
    [k: string]: unknown;
  };
  description?: string;
  descriptionListing?: string;
  dsaProduct?: boolean;
  hazardAndSafety?: string;
  extendedName?: string;
  features?: Feature[];
  images?: Image[];
  jerseyPrint?: JerseyPrint;
  labels?: ProductLabel[];
  manufacturerAID?: string;
  materials?: Material[];
  metaInformationContainer?: {
    [k: string]: unknown;
  };
  name?: string;
  pageTitle?: string;
  price?: {
    selling?: ProductPrice;
    cross?: ProductPrice;
    [k: string]: unknown;
  };
  promotionTypes?: ProductLabel[];
  purchasable?: boolean;
  reviews?: ProductReviews;
  reviewsUrl?: string;
  selectedVariant?: number[];
  sizeChart?: Image;
  sizingSystems?: MapEntry[];
  trusties?: Trusty[];
  photomodelInformation?: PhotomodelInformation;
  url?: string;
  vendors?: Vendor[];
  videos?: VideoEntry[];
}

// -----------------------------------------------------------
// v2/types/pointOfServiceStockResponse.json
// -----------------------------------------------------------

export interface PointOfServiceStockResponse {
  pointOfServiceStocks: PointOfServiceStock[];
}



// -----------------------------------------------------------
// v2/types/pointOfServiceStock.json
// -----------------------------------------------------------

export interface PointOfServiceStock {
  pointOfService?: PointOfService;
  stock: ProductStock;
}



// -----------------------------------------------------------
// v2/types/pointOfServiceResponse.json
// -----------------------------------------------------------

export interface PointOfServiceResponse {
  pointOfServices: PointOfService[];
}



// -----------------------------------------------------------
// v2/types/pointOfServiceFeature.json
// -----------------------------------------------------------

export interface PointOfServiceFeature {
  code: string;
  name?: string;
  type?: string;
  pageUrl?: string;
}

// -----------------------------------------------------------
// v2/types/pointOfService.json
// -----------------------------------------------------------

export interface PointOfService {
  address?: Address;
  openingInfo?: OpeningInfo[];
  coordinates?: Coordinates;
  code: string;
  name?: string;
  url?: string;
  placeId?: string;
  services?: PointOfServiceFeature[];
  description?: string;
}



// -----------------------------------------------------------
// v2/types/player.json
// -----------------------------------------------------------

export interface Player {
  code: string;
  displayName?: string;
  playerNumber?: string;
  originalPrintAvailable?: boolean;
  replicaPrintAvailable?: boolean;
}

// -----------------------------------------------------------
// v2/types/placeOrderRequest.json
// -----------------------------------------------------------

export interface PlaceOrderRequest {
  cart?: Cart;
  wantsClubRegistration?: boolean;
}

// -----------------------------------------------------------
// v2/types/photomodelInformation.json
// -----------------------------------------------------------

export interface PhotomodelInformation {
  name?: string;
  height?: string;
  size?: string;
}

// -----------------------------------------------------------
// v2/types/personalDetailsRequest.json
// -----------------------------------------------------------

export interface PersonalDetailsRequest {
  birthday?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  title?: Title;
}

// -----------------------------------------------------------
// v2/types/paymentStatus.json
// -----------------------------------------------------------

export enum PaymentStatus {
  AUTHORIZED = "AUTHORIZED",
  NOTPAID = "NOTPAID",
  PAID = "PAID"
}

// -----------------------------------------------------------
// v2/types/paymentMode.json
// -----------------------------------------------------------

export interface PaymentMode {
  code: string;
  description?: string;
  headline?: string;
  icon?: string;
  iconBackground?: string;
  mode?: string;
  name?: string;
}

// -----------------------------------------------------------
// v2/types/paymentInformationResponse.json
// -----------------------------------------------------------

export interface PaymentInformationResponse {
  iframeUrl?: string;
  paymentInfos?: MapObjectEntry[];
  paymentModes?: PaymentMode[];
}

// -----------------------------------------------------------
// v2/types/paymentInfo.json
// -----------------------------------------------------------

export interface PaymentInfo {
  code: string;
  creditCardType?: CreditCardType;
  maskedNumber?: string;
  needsVerificationCode?: boolean;
  ownerName?: string;
  paymentModeCode?: string;
  validTo?: string;
  installmentSelected?: boolean;
}

// -----------------------------------------------------------
// v2/types/passwordResetResponse.json
// -----------------------------------------------------------

export interface PasswordResetResponse {
  success: boolean;
}

// -----------------------------------------------------------
// v2/types/passwordResetRequest.json
// -----------------------------------------------------------

export interface PasswordResetRequest {
  password1: string;
  password2: string;
  token: string;
}

// -----------------------------------------------------------
// v2/types/orderTracking.json
// -----------------------------------------------------------

export interface OrderTracking {
  id: string;
  url?: string;
}

// -----------------------------------------------------------
// v2/types/orderStatus.json
// -----------------------------------------------------------

export enum OrderStatus {
  CANCELLING = "CANCELLING",
  CHECKED_VALID = "CHECKED_VALID",
  CREATED = "CREATED",
  HANDLED = "HANDLED",
  CHECKED_INVALID = "CHECKED_INVALID",
  DISCARDED = "DISCARDED",
  ON_VALIDATION = "ON_VALIDATION",
  SUSPENDED = "SUSPENDED",
  COMPLETED = "COMPLETED",
  PAYMENT_AUTHORIZED = "PAYMENT_AUTHORIZED",
  PENDING = "PENDING",
  CANCELLED = "CANCELLED",
  PAYMENT_NOT_AUTHORIZED = "PAYMENT_NOT_AUTHORIZED",
  PAYMENT_AMOUNT_RESERVED = "PAYMENT_AMOUNT_RESERVED",
  PAYMENT_AMOUNT_NOT_RESERVED = "PAYMENT_AMOUNT_NOT_RESERVED",
  PAYMENT_CAPTURED = "PAYMENT_CAPTURED",
  PAYMENT_NOT_CAPTURED = "PAYMENT_NOT_CAPTURED",
  FRAUD_CHECKED = "FRAUD_CHECKED",
  ORDER_SPLIT = "ORDER_SPLIT",
  PROCESSING_ERROR = "PROCESSING_ERROR",
  WAIT_FRAUD_MANUAL_CHECK = "WAIT_FRAUD_MANUAL_CHECK",
  PAYMENT_NOT_VOIDED = "PAYMENT_NOT_VOIDED",
  TAX_NOT_VOIDED = "TAX_NOT_VOIDED",
  TAX_NOT_COMMITTED = "TAX_NOT_COMMITTED",
  TAX_NOT_REQUOTED = "TAX_NOT_REQUOTED"
}

// -----------------------------------------------------------
// v2/types/orderList.json
// -----------------------------------------------------------

export interface OrderList {
  orders?: Order[];
}






// -----------------------------------------------------------
// v2/types/orderEntry.json
// -----------------------------------------------------------

export interface OrderEntry {
  basePrice: ProductPrice;
  crossPrice?: ProductPrice;
  deliveryCost?: ProductPrice;
  deliveryMode?: DeliveryMode;
  deliveryTimes?: MapObjectEntry[];
  entryNumber: number;
  jerseyInfo?: JerseyInfo;
  product: Product;
  quantity: number;
  totalPrice: ProductPrice;
  vendor: Vendor;
}

// -----------------------------------------------------------
// v2/types/order.json
// -----------------------------------------------------------

export interface Order {
  bonusAmount?: ProductPrice;
  code: string;
  consignments?: Consignment[];
  date?: string;
  deliveryAddress?: Address;
  deliveryCost?: ProductPrice;
  diffPrice?: ProductPrice;
  exportStatus?: ExportStatus;
  externalDeliveryStatus?: string;
  giftCardRedemptions?: GiftCardRedemption[];
  originPrice?: ProductPrice;
  paymentAddress?: Address;
  paymentCost?: ProductPrice;
  paymentInfo?: PaymentInfo;
  paymentMode?: PaymentMode;
  paymentStatus?: PaymentStatus;
  status?: OrderStatus;
  subTotalCrossPrice?: ProductPrice;
  subTotalPrice?: ProductPrice;
  totalPrice?: ProductPrice;
  totalTax?: ProductPrice;
  totalPriceWithoutPaymentCosts?: ProductPrice;
  type?: "ESHOP" | "STORE";
  vouchers?: Voucher[];
}






// -----------------------------------------------------------
// v2/types/openingTime.json
// -----------------------------------------------------------

export interface OpeningTime {
  openingTime: TimeOfDay;
  closingTime: TimeOfDay;
}

// -----------------------------------------------------------
// v2/types/openingInfo.json
// -----------------------------------------------------------

export interface OpeningInfo {
  weekDayFrom: Day;
  weekDayTo: Day;
  openingTimes: OpeningTime[];
}



// -----------------------------------------------------------
// v2/types/newsletterUnsubscribeResponse.json
// -----------------------------------------------------------

/**
 * If newsletter unsubscription finished with a success
 */
export interface NewsletterUnsubscriptionStatus {
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/newsletterUnsubscribeRequest.json
// -----------------------------------------------------------

export interface NewsletterUnsubscribeRequest {
  uid: string;
  cid: number;
  llid: number;
  hash?: string;
}

// -----------------------------------------------------------
// v2/types/newsletterSubscribeResponse.json
// -----------------------------------------------------------

export interface NewsletterSubscribeResponse {
  message?: GlobalMessage;
  success: boolean;
}

// -----------------------------------------------------------
// v2/types/newsletterSubscribeRequest.json
// -----------------------------------------------------------

export interface NewsletterSubscribeRequest {
  birthday?: string;
  email: string;
  firstname?: string;
  lastname?: string;
  gender: Gender;
}

// -----------------------------------------------------------
// v2/types/newsletterCategoryUnsubscribeResponse.json
// -----------------------------------------------------------

/**
 * If newsletter unsubscription finished with a success
 */
export interface NewsletterCategoryUnsubscriptionResponse {
  title?: string;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/newsletterCategoryUnsubscribeRequest.json
// -----------------------------------------------------------

export interface NewsletterCategoryUnsubscribeRequest {
  uid: string;
  cid: number;
  fid: number;
  llid: number;
}

// -----------------------------------------------------------
// v2/types/myAccountNavigationResponse.json
// -----------------------------------------------------------

export interface MyAccountNavigationResponse {
  links?: Link[];
}

// -----------------------------------------------------------
// v2/types/modifyCartEntryResponse.json
// -----------------------------------------------------------

export interface ModifyCartEntryResponse {
  cart: Cart;
  modificationStatus:
    | "MAX_ORDER_QUANTITY_EXCEEDED"
    | "SUCCESS"
    | "LOW_STOCK"
    | "NO_STOCK"
    | "PRODUCT_UNAVAILABLE"
    | "CART_LIMIT_EXCEEDED"
    | "UNAVAILABLE"
    | "SUCCESSFULLY_REMOVED"
    | "UNKOWN";
}

// -----------------------------------------------------------
// v2/types/modifyCartEntryRequest.json
// -----------------------------------------------------------

export interface ModifyCartEntryRequest {
  entryNumber: number;
  quantity?: number;
  productCode?: string;
}

// -----------------------------------------------------------
// v2/types/metaInformationContainer.json
// -----------------------------------------------------------

export interface MetaInformationContainer {
  metaDescription?: string;
  name?: string;
  title?: string;
}

// -----------------------------------------------------------
// v2/types/megaDropdownCollection.json
// -----------------------------------------------------------

export interface MegaDropdownCollection {
  entries?: MegaDropdown[];
}

// -----------------------------------------------------------
// v2/types/megaDropdownCategory.json
// -----------------------------------------------------------

export interface MegaDropdownCategory {
  id: string;
  selected?: boolean;
  link: ImageLink;
  hide?: boolean;
  hideInNavigation?: boolean;
  subCategories?: MegaDropdownCategory[];
  trendyCategories?: MegaDropdownCategory[];
  specials?: ImageLink[];
}

// -----------------------------------------------------------
// v2/types/megaDropdown.json
// -----------------------------------------------------------

export interface MegaDropdown {
  firstHeadline?: string;
  secondHeadline?: string;
  displayType: "BRANDS" | "IMAGE" | "IMAGETITLE" | "LINK_ONLY" | "LIST" | "LISTTITLE" | "MASONRY" | "MASONRYGRID";
  category: MegaDropdownCategory;
  color?: string;
  openInMegaDropdown?: boolean;
  manualLink?: ImageLink;
  id?: string;
  maxOtherEntries?: number;
  isBrandMenu?: boolean;
}

// -----------------------------------------------------------
// v2/types/material.json
// -----------------------------------------------------------

export interface Material {
  code: string;
  name: string;
}

// -----------------------------------------------------------
// v2/types/mapObjectEntry.json
// -----------------------------------------------------------

export interface MapObjectEntry {
  key: string;
  value: unknown;
}

// -----------------------------------------------------------
// v2/types/mapEntry.json
// -----------------------------------------------------------

export interface MapEntry {
  key: string;
  value: string;
}

// -----------------------------------------------------------
// v2/types/loginResponse.json
// -----------------------------------------------------------

export interface LoginResponse {
  errors?: FormError[];
  remainingTries?: number;
  success: boolean;
}

// -----------------------------------------------------------
// v2/types/link.json
// -----------------------------------------------------------

export interface Link {
  url: string;
  title: string;
  subLinks?: Link[];
}

// -----------------------------------------------------------
// v2/types/language.json
// -----------------------------------------------------------

export interface Language {
  isocode: string;
  name: string;
  shortName?: string;
  url: string;
}

// -----------------------------------------------------------
// v2/types/jerseyPrintConfig.json
// -----------------------------------------------------------

export interface JerseyPrintConfig {
  enabled?: boolean;
  showPlayer?: boolean;
  price?: ServiceProductPrice;
}

// -----------------------------------------------------------
// v2/types/jerseyPrint.json
// -----------------------------------------------------------

export interface JerseyPrint {
  available?: boolean;
  playernameValidationPattern?: string;
  originalPrint?: JerseyPrintConfig;
  replicaPrint?: JerseyPrintConfig;
  clubs?: Club[];
}

// -----------------------------------------------------------
// v2/types/jerseyInfo.json
// -----------------------------------------------------------

export interface JerseyInfo {
  playerName?: string;
  playerNumber?: string;
  price?: ProductPrice;
  replica?: boolean;
  totalPrice?: ProductPrice;
}

// -----------------------------------------------------------
// v2/types/installmentCondition.json
// -----------------------------------------------------------

export interface InstallmentCondition {
  originAmount?: ProductPrice;
  installmentFeeTotal?: ProductPrice;
  installments?: ProductPrice[];
  totalAmount?: ProductPrice;
}

// -----------------------------------------------------------
// v2/types/imageTeaserComponent.json
// -----------------------------------------------------------

export interface ImageTeaserComponent {
  icon?: string;
  image: Image;
  title?: string;
  headline?: string;
  text?: string;
  uid: string;
  url: string;
  isSquare?: boolean;
  trackingId?: string;
}

// -----------------------------------------------------------
// v2/types/imageLink.json
// -----------------------------------------------------------

export interface ImageLink {
  assetId?: string;
  icon?: string;
  url: string;
  title: string;
  color?: string;
}

// -----------------------------------------------------------
// v2/types/image.json
// -----------------------------------------------------------

export interface Image {
  assetId: string;
  altText?: string;
  imageType?: "PRIMARY" | "GALLERY" | "SRCSET" | "SPINNER" | "VIDEO";
  title?: string;
  aspectRatio?: string;
  imageObjectPosition?:
    | "BOTTOM"
    | "CENTER"
    | "LEFT"
    | "LEFT_BOTTOM"
    | "LEFT_TOP"
    | "RIGHT"
    | "RIGHT_BOTTOM"
    | "RIGHT_TOP"
    | "TOP";
}

// -----------------------------------------------------------
// v2/types/header.json
// -----------------------------------------------------------

export interface Header {
  slots?: ContentSlot[];
}

// -----------------------------------------------------------
// v2/types/guestRegistrationResponse.json
// -----------------------------------------------------------

export interface GuestRegistrationResponse {}

// -----------------------------------------------------------
// v2/types/guestRegistrationRequest.json
// -----------------------------------------------------------

export interface GuestRegistrationRequest {
  email?: string;
}

// -----------------------------------------------------------
// v2/types/guestRealRegistrationResponse.json
// -----------------------------------------------------------

export interface GuestRealRegistrationResponse {
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/guestRealRegistrationRequest.json
// -----------------------------------------------------------

export interface GuestRealRegistrationRequest {
  password?: string;
  termsAndConditionsChecked?: boolean;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/gridLayoutRowType.json
// -----------------------------------------------------------

export enum GridLayoutRowType {
  PERCENT_100 = "PERCENT_100",
  PERCENT_50 = "PERCENT_50",
  FULL_WIDTH = "FULL_WIDTH",
  HERO = "HERO"
}

// -----------------------------------------------------------
// v2/types/gridLayoutRow.json
// -----------------------------------------------------------

export interface GridLayoutRow {
  type: GridLayoutRowType;
}



// -----------------------------------------------------------
// v2/types/gridLayoutExpiringShortage.json
// -----------------------------------------------------------

export interface GridLayoutExpiringShortage {
  endTime: string;
  startTime: string;
}

// -----------------------------------------------------------
// v2/types/gridLayoutDeviceShortage.json
// -----------------------------------------------------------

export interface GridLayoutDeviceShortage {
  devices?: ("DESKTOP" | "MOBILE")[];
}

// -----------------------------------------------------------
// v2/types/gridLayoutData.json
// -----------------------------------------------------------

export interface GridLayoutData {
  data: unknown;
  deviceShortage?: GridLayoutDeviceShortage;
  expiringShortage?: GridLayoutExpiringShortage;
}

// -----------------------------------------------------------
// v2/types/gridLayoutBackgroundPosition.json
// -----------------------------------------------------------

export enum GridLayoutBackgroundPosition {
  FULL = "FULL",
  PARTIAL = "PARTIAL"
}

// -----------------------------------------------------------
// v2/types/gridLayout.json
// -----------------------------------------------------------

export interface GridLayout {
  "background-position"?: GridLayoutBackgroundPosition;
  code: string;
  ctaButton?: Button;
  datas?: GridLayoutData[];
  headline?: string;
  rows?: GridLayoutRow[];
  type?: GridLayoutRowType;
}




// -----------------------------------------------------------
// v2/types/globalMessage.json
// -----------------------------------------------------------

export interface GlobalMessage {
  message?: string;
  severity?: "SUCCESS" | "INFO" | "ERROR";
}

// -----------------------------------------------------------
// v2/types/giftcardresponse.json
// -----------------------------------------------------------

export interface GiftCardResponse {
  amountUsed?: number;
  amountRequested?: number;
  balanceNew?: number;
  balanceOld?: number;
  cardId: string;
  cardPIN: string;
  cardValidityDate?: {
    [k: string]: unknown;
  };
  displayMessage?: string;
  returnCode?: string;
  transactionId?: string;
  transactionType?: "CHARGE" | "COMMIT" | "GET_BALANCE" | "REDEEM" | "RELEASE";
}

// -----------------------------------------------------------
// v2/types/giftcardrequest.json
// -----------------------------------------------------------

export interface GiftCardRequest {
  amount?: number;
  cardId: string;
  cardPIN: string;
}

// -----------------------------------------------------------
// v2/types/giftCardRedemption.json
// -----------------------------------------------------------

export interface GiftCardRedemption {
  amount: ProductPrice;
  balance: ProductPrice;
  cardId: string;
  status: "RELEASED" | "RESERVED" | "APPROVED" | "REJECTED";
}

// -----------------------------------------------------------
// v2/types/genderList.json
// -----------------------------------------------------------

export interface GenderList {
  genders?: Gender[];
}

// -----------------------------------------------------------
// v2/types/gender.json
// -----------------------------------------------------------

export interface Gender {
  code: string;
  name?: string;
  selected?: boolean;
  url?: string;
}

// -----------------------------------------------------------
// v2/types/formFieldValidations.json
// -----------------------------------------------------------

export interface FormFieldValidations {
  maxLength?: FormFieldMaxLengthValidation;
  minLength?: FormFieldMinLengthValidation;
  regex?: FormFieldRegexValidation;
  required?: FormFieldRequiredValidation;
}

// -----------------------------------------------------------
// v2/types/formFieldValidation.json
// -----------------------------------------------------------

export interface FormFieldValidation {
  errorMessage?: string;
}

// -----------------------------------------------------------
// v2/types/formFieldType.json
// -----------------------------------------------------------

export enum FormFieldType {
  CAPTCHA = "CAPTCHA",
  CHECKBOX = "CHECKBOX",
  EMAIL = "EMAIL",
  LABEL = "LABEL",
  PASSWORD = "PASSWORD",
  RADIO_BUTTON = "RADIO_BUTTON",
  RADIO_GROUP = "RADIO_GROUP",
  SELECT = "SELECT",
  SUBMIT = "SUBMIT",
  TEXTFIELD = "TEXTFIELD"
}

// -----------------------------------------------------------
// v2/types/formFieldRequiredValidation.json
// -----------------------------------------------------------

export interface FormFieldRequiredValidation extends FormFieldValidation {
  required?: boolean;
}

// -----------------------------------------------------------
// v2/types/formFieldRegexValidation.json
// -----------------------------------------------------------

export interface FormFieldRegexValidation extends FormFieldValidation {
  regexPattern?: string;
}

// -----------------------------------------------------------
// v2/types/formFieldMinLengthValidation.json
// -----------------------------------------------------------

export interface FormFieldMinLengthValidation extends FormFieldValidation {
  minLength?: number;
}

// -----------------------------------------------------------
// v2/types/formFieldMaxLengthValidation.json
// -----------------------------------------------------------

export interface FormFieldMaxLengthValidation extends FormFieldValidation {
  maxLength?: number;
}

// -----------------------------------------------------------
// v2/types/formFieldInputMode.json
// -----------------------------------------------------------

export enum FormFieldInputMode {
  DATE = "DATE",
  DECIMAL = "DECIMAL",
  EMAIL = "EMAIL",
  NONE = "NONE",
  NUMERIC = "NUMERIC",
  SEARCH = "SEARCH",
  TEL = "TEL",
  TEXT = "TEXT",
  TEXTAREA = "TEXTAREA",
  URL = "URL"
}

// -----------------------------------------------------------
// v2/types/formField.json
// -----------------------------------------------------------

export interface FormField {
  autocomplete?: boolean;
  buttons?: FormField[];
  checked?: boolean;
  disabled?: boolean;
  id: string;
  inputMode?: FormFieldInputMode;
  label?: string;
  placeholder?: string;
  styleClass?: string;
  type?: FormFieldType;
  validations?: FormFieldValidations;
  value?: string;
}




// -----------------------------------------------------------
// v2/types/formError.json
// -----------------------------------------------------------

export interface FormError {
  errorMessage?: string;
  field?: string;
}

// -----------------------------------------------------------
// v2/types/formConfiguration.json
// -----------------------------------------------------------

export interface FormConfiguration {
  autocomplete?: boolean;
  code: string;
  fields?: FormField[];
}




// -----------------------------------------------------------
// v2/types/forgottenPaswordResponse.json
// -----------------------------------------------------------

export interface ForgottenPaswordResponse {}

// -----------------------------------------------------------
// v2/types/forgottenPaswordRequest.json
// -----------------------------------------------------------

export interface ForgottenPaswordRequest {
  email?: string;
}

// -----------------------------------------------------------
// v2/types/feedbackText.json
// -----------------------------------------------------------

export interface FeedbackText {
  id?: number;
  label?: string;
  text?: string;
}

// -----------------------------------------------------------
// v2/types/feature.json
// -----------------------------------------------------------

export interface Feature {
  code: string;
  name?: string;
  description?: string;
  image?: Image;
}

// -----------------------------------------------------------
// v2/types/exportStatus.json
// -----------------------------------------------------------

export enum ExportStatus {
  NOTEXPORTED = "NOTEXPORTED",
  READY_FOR_EXPORT = "READY_FOR_EXPORT",
  EXPORTED = "EXPORTED",
  WAITING_FOR_ERP_FEEDBACK = "WAITING_FOR_ERP_FEEDBACK",
  FAILED_EXPORT = "FAILED_EXPORT"
}

// -----------------------------------------------------------
// v2/types/errorResponse.json
// -----------------------------------------------------------

export interface ErrorResponse {
  message?: string;
}

// -----------------------------------------------------------
// v2/types/dyProductRecommendationComponent.json
// -----------------------------------------------------------

export interface DYProductRecommendationComponent {
  selectorName?: string;
  uid: string;
  trackingId?: string;
  visibleDatasDesktop?: number;
  visibleDatasTablet?: number;
  visibleDatasMobile?: number;
}

// -----------------------------------------------------------
// v2/types/deliveryTime.json
// -----------------------------------------------------------

export interface DeliveryTime {
  from?: number;
  to?: number;
}

// -----------------------------------------------------------
// v2/types/deliveryStatus.json
// -----------------------------------------------------------

export enum DeliveryStatus {
  NOTSHIPPED = "NOTSHIPPED",
  SHIPPED = "SHIPPED",
  UNKNOWN = "UNKNOWN"
}

// -----------------------------------------------------------
// v2/types/deliveryModeRequestEntry.json
// -----------------------------------------------------------

export interface DeliveryModeRequestEntry {
  code: string;
  cartEntryNumber: number;
}

// -----------------------------------------------------------
// v2/types/deliveryModeRequest.json
// -----------------------------------------------------------

export interface DeliveryModeRequest {
  entries?: DeliveryModeRequestEntry[];
  phone?: string;
}

// -----------------------------------------------------------
// v2/types/deliveryMode.json
// -----------------------------------------------------------

export interface DeliveryMode {
  assembly?: boolean;
  code: string;
  default?: boolean;
  deliveryCost?: ProductPrice;
  forwarding?: boolean;
  icon?: string;
  name?: string;
}

// -----------------------------------------------------------
// v2/types/deliveryInformation.json
// -----------------------------------------------------------

export interface DeliveryInformation {
  retoureDescription?: string;
  shippingDescription?: string;
}

// -----------------------------------------------------------
// v2/types/day.json
// -----------------------------------------------------------

export enum Day {
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY"
}

// -----------------------------------------------------------
// v2/types/customerReviewsResponse.json
// -----------------------------------------------------------

export interface CustomerReviewsResponse {
  reviews?: CustomerReview[];
}

// -----------------------------------------------------------
// v2/types/customerReview.json
// -----------------------------------------------------------

export interface CustomerReview {
  code: string;
  comment?: string;
  headline?: string;
  helpfulNoCount?: number;
  helpfulYesCount?: number;
  firstName?: string;
  lastName?: string;
  rating?: number;
  reviewDate?: string;
  city?: string;
}

// -----------------------------------------------------------
// v2/types/customerGiftCardsResponse.json
// -----------------------------------------------------------

export interface CustomerGiftCardsResponse {
  giftCards?: CustomerGiftCard[];
  totalAmount?: ProductPrice;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/customerGiftCard.json
// -----------------------------------------------------------

export interface CustomerGiftCard {
  cardValidityDate?: string;
  currentValue?: number;
  currentValueFormatted?: string;
  initialValue?: number;
  initialValueFormatted?: string;
  number?: string;
  pin?: string;
  url?: string;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/currentNavigation.json
// -----------------------------------------------------------

export interface CurrentNavigation {
  code: string;
  name?: string;
}

// -----------------------------------------------------------
// v2/types/creditCardType.json
// -----------------------------------------------------------

export interface CreditCardType {
  code: string;
  icon?: string;
  name?: string;
}

// -----------------------------------------------------------
// v2/types/country.json
// -----------------------------------------------------------

export interface Country {
  iso: string;
  name: string;
}

// -----------------------------------------------------------
// v2/types/coordinates.json
// -----------------------------------------------------------

export interface Coordinates {
  latitude: number;
  longitude: number;
}

// -----------------------------------------------------------
// v2/types/contentSlot.json
// -----------------------------------------------------------

export interface ContentSlot {
  position: string;
  components?: unknown[];
}

// -----------------------------------------------------------
// v2/types/contentNavigationNode.json
// -----------------------------------------------------------

export interface ContentNavigationNode {
  children?: ContentNavigationNode[];
  code?: string;
  href?: string;
  index?: boolean;
  label?: string;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/contentNavigation.json
// -----------------------------------------------------------

export interface ContentNavigation {
  navigationNodes?: ContentNavigationNode[];
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/contactFormRequest.json
// -----------------------------------------------------------

export interface ContactFormRequest {
  requestType: string;
  myRequest?: string;
  salutation?: string;
  firstName: string;
  lastName: string;
  company?: string;
  email: string;
  customerNr?: string;
  phoneNr?: string;
  orderNr?: string;
  articleNr?: string;
  clubNr?: string;
}

// -----------------------------------------------------------
// v2/types/consignment.json
// -----------------------------------------------------------

export interface Consignment {
  code: string;
  delvieryMode?: DeliveryMode;
  delvieryStatus?: DeliveryStatus;
  entries?: OrderEntry[];
  tracking?: OrderTracking;
}



// -----------------------------------------------------------
// v2/types/communicationCenterProperty.json
// -----------------------------------------------------------

export interface CommunicationCenterProperty {
  id: string;
  value: string;
}

// -----------------------------------------------------------
// v2/types/communicationCenterGroupEntry.json
// -----------------------------------------------------------

export interface CommunicationCenterGroupEntry {
  title?: string;
  description?: string;
  type?: "default" | "select" | "sms";
  id?: string;
  enabled?: boolean;
  value?: string;
}

// -----------------------------------------------------------
// v2/types/communicationCenterGroup.json
// -----------------------------------------------------------

export interface CommunicationCenterGroup {
  title?: string;
  description?: string;
  type?: "default" | "club";
  communicationCenterGroupEntries?: CommunicationCenterGroupEntry[];
}

// -----------------------------------------------------------
// v2/types/communicationCenterCategoryResult.json
// -----------------------------------------------------------

export interface CommunicationCenterCategoryResult {
  success?: boolean;
}

// -----------------------------------------------------------
// v2/types/communicationCenterCategory.json
// -----------------------------------------------------------

export interface CommunicationCenterCategory {
  id: string;
  enabled: boolean;
}

// -----------------------------------------------------------
// v2/types/communicationCenter.json
// -----------------------------------------------------------

export interface CommunicationCenter {
  communicationCenterGroups?: CommunicationCenterGroup[];
}

// -----------------------------------------------------------
// v2/types/cmsImagesWrapper.json
// -----------------------------------------------------------

export interface CmsImagesWrapper {
  desktop: Image;
  mobile?: Image;
}

// -----------------------------------------------------------
// v2/types/clubSmsResponse.json
// -----------------------------------------------------------

export interface ClubSmsResponse {
  success: boolean;
}

// -----------------------------------------------------------
// v2/types/clubSmsRequest.json
// -----------------------------------------------------------

export interface ClubSmsRequest {
  email: string;
  hash?: string;
  phone: string;
  consent: boolean;
}

// -----------------------------------------------------------
// v2/types/clubRegisterRequest.json
// -----------------------------------------------------------

export interface ClubRegisterRequest {
  clubId?: string;
  email?: string;
  new?: boolean;
}

// -----------------------------------------------------------
// v2/types/clubRegisterPosResponse.json
// -----------------------------------------------------------

export interface ClubRegisterPosResponse {
  addToStocardLink?: string;
  birthday?: string;
  clubNumber?: string;
  clubNumberImageUrl?: string;
  email?: string;
  firstName?: string;
  isNewMember?: boolean;
  lastName?: string;
  mobilePhone?: string;
  zip?: string;
}

// -----------------------------------------------------------
// v2/types/clubRegisterPosRequest.json
// -----------------------------------------------------------

export interface ClubRegisterPosRequest {
  birthday?: string;
  email?: string;
  firstName?: string;
  gender?: ClubGender;
  lastName?: string;
  mobilePhone?: string;
  termsAndConditionsChecked?: boolean;
  zip?: string;
}



// -----------------------------------------------------------
// v2/types/clubProcessState.json
// -----------------------------------------------------------

export interface ClubProcessState {
  clubDiscount?: ProductPrice;
  clubId?: string;
  email?: string;
  enabled?: boolean;
  label?: ProductLabel;
  messages?: GlobalMessage[];
}

// -----------------------------------------------------------
// v2/types/clubInfoPosResponse.json
// -----------------------------------------------------------

export interface ClubInfoPosResponse {
  backLink?: string;
  birthday?: string;
  clubNumber?: string;
  clubNumberImageUrl?: string;
  email?: string;
  firstName?: string;
  isClubMember?: boolean;
  lastName?: string;
  mobilePhone?: string;
  zip?: string;
}

// -----------------------------------------------------------
// v2/types/clubInfoPosRequest.json
// -----------------------------------------------------------

export interface ClubInfoPosRequest {
  email?: string;
}

// -----------------------------------------------------------
// v2/types/clubInfo.json
// -----------------------------------------------------------

export interface ClubInfo {
  amount?: ProductPrice;
  bonus?: ProductPrice;
  clubId?: string;
}

// -----------------------------------------------------------
// v2/types/clubGender.json
// -----------------------------------------------------------

export enum ClubGender {
  male = "male",
  female = "female"
}

// -----------------------------------------------------------
// v2/types/club.json
// -----------------------------------------------------------

export interface Club {
  code: string;
  name?: string;
  originalPrintAvailable?: boolean;
  replicaPrintAvailable?: boolean;
  players?: Player[];
}

// -----------------------------------------------------------
// v2/types/clearCacheResponse.json
// -----------------------------------------------------------

export interface ClearCacheResponse {
  status?: string;
  cacheNames?: string[];
  message?: string;
}

// -----------------------------------------------------------
// v2/types/classificationValue.json
// -----------------------------------------------------------

export interface ClassificationValue {
  value?: string;
}

// -----------------------------------------------------------
// v2/types/classification.json
// -----------------------------------------------------------

export interface Classification {
  code: string;
  name?: string;
  visible?: boolean;
  values?: ClassificationValue[];
  unit?: string;
}

// -----------------------------------------------------------
// v2/types/checkoutStep.json
// -----------------------------------------------------------

export enum CheckoutStep {
  CART = "CART",
  LOGIN_AND_REGISTRATION = "LOGIN_AND_REGISTRATION",
  DELIVERY_ADDRESS = "DELIVERY_ADDRESS",
  CLICK_AND_COLLECT = "CLICK_AND_COLLECT",
  PAYMENT_ADDRESS = "PAYMENT_ADDRESS",
  DELIVERY_MODE = "DELIVERY_MODE",
  PAYMENT_MODE = "PAYMENT_MODE",
  EXTERNAL_PAGE = "EXTERNAL_PAGE",
  SUMMARY = "SUMMARY",
  CONFIRMATION = "CONFIRMATION"
}

// -----------------------------------------------------------
// v2/types/checkoutState.json
// -----------------------------------------------------------

export interface CheckoutState {
  allowedSteps?: CheckoutStep[];
  cart?: Cart;
  currentStep: CheckoutStep;
  externalRedirectUrl?: string;
  messages?: GlobalMessage[];
  order?: Order;
}







// -----------------------------------------------------------
// v2/types/checkoutConfirmationResponse.json
// -----------------------------------------------------------

export interface CheckoutConfirmationResponse {
  customerEmail?: string;
  remainingValueGiftCards?: RemainingValueGiftCards[];
  orderCode?: string;
  redirectOrderCode?: string;
  showGuestRegistration?: boolean;
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/categoryTeaserComponent.json
// -----------------------------------------------------------

export interface CategoryTeaserComponent {
  categories?: Category[];
  uid: string;
  showDYAffinities?: boolean;
  trackingId?: string;
}

// -----------------------------------------------------------
// v2/types/categorySliderComponent.json
// -----------------------------------------------------------

export interface CategorySliderComponent {
  categories?: Category[];
  brand?: Brand;
  uid: string;
  trackingId?: string;
}

// -----------------------------------------------------------
// v2/types/category.json
// -----------------------------------------------------------

export interface Category {
  code: string;
  name: string;
  supercategory?: Category;
  url: string;
  amount?: number;
  image?: Image;
  products?: Product[];
}

// -----------------------------------------------------------
// v2/types/cartEntry.json
// -----------------------------------------------------------

export interface CartEntry {
  basePrice: ProductPrice;
  crossPrice?: ProductPrice;
  deliveryCost?: ProductPrice;
  deliveryMode?: DeliveryMode;
  deliveryTimes?: MapObjectEntry[];
  entryNumber: number;
  jerseyInfo?: JerseyInfo;
  maxQuantity: number;
  product: Product;
  quantity: number;
  totalPrice: ProductPrice;
  vendor: Vendor;
}

// -----------------------------------------------------------
// v2/types/cart.json
// -----------------------------------------------------------

export interface Cart {
  clubDiscount?: ProductPrice;
  deliveryAddress?: Address;
  deliveryCost: ProductPrice;
  diffPrice?: ProductPrice;
  entries?: CartEntry[];
  giftCardRedemptions?: GiftCardRedemption[];
  originPrice?: ProductPrice;
  paymentAddress?: Address;
  paymentCost: ProductPrice;
  paymentInfo?: PaymentInfo;
  paymentMode?: PaymentMode;
  subTotalCrossPrice?: ProductPrice;
  subTotalPrice: ProductPrice;
  totalPrice: ProductPrice;
  totalTax?: ProductPrice;
  totalPriceWithoutPaymentCosts?: ProductPrice;
  vouchers?: Voucher[];
}

// -----------------------------------------------------------
// v2/types/campaignTeaserComponent.json
// -----------------------------------------------------------

export interface CampaignTeaserComponent {
  button: Button;
  images: CmsImagesWrapper;
  headline?: Text;
  text?: string;
  uid: string;
  trackingId?: string;
}

// -----------------------------------------------------------
// v2/types/campaign.json
// -----------------------------------------------------------

export interface Campaign {
  name?: string;
  feedbackText?: FeedbackText[];
}

// -----------------------------------------------------------
// v2/types/button.json
// -----------------------------------------------------------

export interface Button {
  text?: string;
  target?: string;
  url?: string;
}

// -----------------------------------------------------------
// v2/types/breadcrumb.json
// -----------------------------------------------------------

export interface Breadcrumb {
  name: string;
  title?: string;
  url?: string;
}

// -----------------------------------------------------------
// v2/types/brandsResponse.json
// -----------------------------------------------------------

export interface BrandsResponse {
  brands?: Brand[];
  [k: string]: unknown;
}

// -----------------------------------------------------------
// v2/types/brandTeaserComponent.json
// -----------------------------------------------------------

export interface BrandTeaserComponent {
  brands?: Brand[];
  uid: string;
  showDYAffinities?: boolean;
  trackingId?: string;
}

// -----------------------------------------------------------
// v2/types/brand.json
// -----------------------------------------------------------

export interface Brand {
  code: string;
  image: Image;
  name: string;
  url: string;
}

// -----------------------------------------------------------
// v2/types/bikeFinder.json
// -----------------------------------------------------------

export interface BikeFinder {
  featureMode?: "classification" | "descriptionListing";
  categories?: Category[];
}

// -----------------------------------------------------------
// v2/types/bar.json
// -----------------------------------------------------------

export interface Bar {
  cookieExpires?: number;
  banners?: Banner[];
  color?: string;
  backgroundColor?: string;
  closable?: boolean;
  devices?: ("MOBILE" | "DESKTOP")[];
}

// -----------------------------------------------------------
// v2/types/banner.json
// -----------------------------------------------------------

export interface Banner {
  iconId?: string;
  text: string;
  title?: string;
  url?: string;
  isExternal?: boolean;
  target?: "sameWindow" | "newWindow";
  color?: string;
  backgroundColor?: string;
}

// -----------------------------------------------------------
// v2/types/autocompleteResponse.json
// -----------------------------------------------------------

export interface AutocompleteResponse {
  brands?: Brand[];
  categories?: Category[];
  products?: Product[];
  searchTerm: string;
}

// -----------------------------------------------------------
// v2/types/asyncComponentInfo.json
// -----------------------------------------------------------

export interface AsyncComponentInfo {
  url?: string;
  data?: unknown;
}

// -----------------------------------------------------------
// v2/types/addressList.json
// -----------------------------------------------------------

export interface AddressList {
  addresses?: Address[];
}

// -----------------------------------------------------------
// v2/types/address.json
// -----------------------------------------------------------

export interface Address {
  additionalAddress?: string;
  city?: string;
  country?: Country;
  default: boolean;
  firstName?: string;
  id?: string;
  isBilling: boolean;
  isDelivery: boolean;
  lastName?: string;
  phone?: string;
  pointOfServiceCode?: string;
  streetAndNumber?: string;
  title?: Title;
  zip?: string;
}

// -----------------------------------------------------------
// v2/types/addToCartResponse.json
// -----------------------------------------------------------

export interface AddToCartResponse {
  addedQuantity: number;
  cart: Cart;
  cartTotalUnitCount: number;
  entryNumber: number;
  modificationStatus:
    | "MAX_ORDER_QUANTITY_EXCEEDED"
    | "SUCCESS"
    | "LOW_STOCK"
    | "NO_STOCK"
    | "PRODUCT_UNAVAILABLE"
    | "CART_LIMIT_EXCEEDED"
    | "UNAVAILABLE"
    | "SUCCESSFULLY_REMOVED"
    | "UNKOWN";
  requestedQuantity: number;
  voucherReleased: boolean;
  legacyResponse?: unknown;
}

// -----------------------------------------------------------
// v2/types/addToCartRequest.json
// -----------------------------------------------------------

export interface AddToCartRequest {
  productCode: string;
  quantity: number;
  jersey?: AddToCartJersey;
}

// -----------------------------------------------------------
// v2/types/addToCartJersey.json
// -----------------------------------------------------------

export interface AddToCartJersey {
  isExistingClub: boolean;
  isReplica?: boolean;
  name?: string;
  number?: string;
}
