import type { Store } from 'vuex';
import { useStoreModule } from '../useStoreModule';
import serverContextStore from './serverContext.store';
import serverContextApi from './serverContext.api';
import { RootState, ServerContextState } from '~/@api/store.types';
import { SERVERCONTEXT } from '~/@constants/store';

export function useServerContextStore(store: Store<RootState>) {
	return useStoreModule<ServerContextState, ReturnType<typeof serverContextApi>>(
		SERVERCONTEXT,
		store,
		serverContextStore,
		serverContextApi,
	);
}
