import { Context } from '@nuxt/types';
import { useRoutingStore } from '~/@api/store/routingApi';

// No performance optimization by deferring onNuxtReady to ensure digitalData state
// will be up to date immediately
// eslint-disable-next-line require-await
async function PluginIntegration(context: Context) {
	(window as any).appState = context.store.state;
	(window as any).digitalData = useRoutingStore(context.store).state.spaData.datalayer;
}

// Do not defer this plugin until Nuxt is ready, to watch for occuring user interaction
export default function (context: Context, _) {
	PluginIntegration(context);
}
