// Blocking time optimized aca recommendations from
// https://github.com/nuxt/nuxt.js/discussions/9061#discussioncomment-539483

// Importing dependencies - statically ------
import { Context } from '@nuxt/types';
import { AsyncComponent, Component } from 'vue/types/options';

// Importing dependencies - dynamically ------
import { importLogger, importVue, importVueCustomElement } from '~/app-utils/dynamic-imports';

type VueWebComponent = Component<any, any, any, any> | AsyncComponent<any, any, any, any>;

const PREFIX = 'osp';

const wrapComponent = (component: VueWebComponent) => ({
	props: {
		props: { required: false, type: String },
	},
	render(h, _context) {
		return h(component, { props: this.props ? JSON.parse(this.props) : {} });
	},
});

const installWebComponents = async (
	components: { [name: string]: VueWebComponent },
	_context: Context,
) => {
	const { default: Vue } = await importVue();
	const { default: Logger } = await importLogger();

	Object.keys(components).forEach((name) => {
		Logger.info(`Installing ${name} web-component...`);
		Vue.customElement(`${PREFIX}-${name}`, wrapComponent(components[name]), {
			beforeCreateVueInstance(RootComponentDefinition) {
				RootComponentDefinition.i18n = _context.app.i18n;

				return RootComponentDefinition;
			},
		});
	});
};

// Defer until usage
async function PluginIntegration(context: Context) {
	// Wait until all dependencies were loaded asynchronously in parallel
	const dynamicImportResponses = await Promise.all([importVue(), importVueCustomElement()]);

	const Vue = dynamicImportResponses[0].default;
	const VueCustomElement = dynamicImportResponses[1].default;

	Vue.use(VueCustomElement);

	await installWebComponents(
		{
			'brand-list': () =>
				import(
					/* webpackChunkName: "webcomponents" */ '@/node_modules/@osp/design-system/components/BrandList/BrandList.vue'
				).then((m) => m.default || m),
			'campaign-teaser': () =>
				import(
					/* webpackChunkName: "webcomponents" */ '@/node_modules/@osp/design-system/components/CampaignTeaser/CampaignTeaser.vue'
				).then((m) => m.default || m),
			ico: () =>
				import(
					/* webpackChunkName: "webcomponents" */ '@/node_modules/@osp/design-system/components/Ico/Ico.vue'
				).then((m) => m.default || m),
			'image-tile': () =>
				import(
					/* webpackChunkName: "webcomponents" */ '@/node_modules/@osp/design-system/components/ImageTile/ImageTile.vue'
				).then((m) => m.default || m),
			'image-tile-with-description': () =>
				import(
					/* webpackChunkName: "webcomponents" */ '@/node_modules/@osp/design-system/components/ImageTileWithDescription/ImageTileWithDescription.vue'
				).then((m) => m.default || m),
			inlinesvg: () =>
				import(
					/* webpackChunkName: "webcomponents" */ '@/node_modules/@osp/design-system/components/InlineSvg/InlineSvg.vue'
				).then((m) => m.default || m),
			'promotion-teaser': () =>
				import(
					/* webpackChunkName: "webcomponents" */ '@/node_modules/@osp/design-system/components/PromotionTeaser/PromotionTeaser.vue'
				).then((m) => m.default || m),
			'section-title': () =>
				import(
					/* webpackChunkName: "webcomponents" */ '@/node_modules/@osp/design-system/components/SectionTitle/SectionTitle.vue'
				).then((m) => m.default || m),
			testimonial: () =>
				import(
					/* webpackChunkName: "webcomponents" */ '@/node_modules/@osp/design-system/components/Testimonial/Testimonial.vue'
				).then((m) => m.default || m),
			'usp-bar': () =>
				import(
					/* webpackChunkName: "webcomponents" */ '@/node_modules/@osp/design-system/components/UspBar/UspBar.vue'
				).then((m) => m.default || m),
			slider: () =>
				import(
					/* webpackChunkName: "webcomponents" */ '@/node_modules/@osp/design-system/components/Slider/Slider.vue'
				).then((m) => m.default || m),
			'promo-bar': () =>
				import(
					/* webpackChunkName: "webcomponents" */ '@/node_modules/@osp/design-system/components/PromoBar/PromoBar.vue'
				).then((m) => m.default || m),
			countdown: () =>
				import(
					/* webpackChunkName: "webcomponents" */ '@/node_modules/@osp/design-system/components/Countdown/Countdown.vue'
				).then((m) => m.default || m),
		},
		context,
	);
}

// Do not defer this plugin until Nuxt is ready, but execute logic in background to reduce blocking time
export default function (context: Context, _) {
	PluginIntegration(context);
}
