// Blocking time optimized aca recommendations from
// https://github.com/nuxt/nuxt.js/discussions/9061#discussioncomment-539483

// Importing dependencies - statically ------
import Vue from 'vue';
import { Breakpoint } from '@/node_modules/@osp/design-system/types/breakpoint';
import { useMediaqueryStore } from '~/@api/store/mediaqueryApi';
import { useServerContextStore } from '~/@api/store/serverContextApi';

// Plugin ------------------------------------------------------------------------------------------
Vue.mixin({
	computed: {
		BREAKPOINT(): Breakpoint {
			if (process.client) {
				const { api: mediaqueryApi } = useMediaqueryStore(this.$store);

				return {
					IS_MOBILE: mediaqueryApi.isMobile(),
					IS_TABLET: mediaqueryApi.isTablet(),
					IS_DESKTOP: mediaqueryApi.isDesktop(),
				};
			}

			const { state: serverContextState } = useServerContextStore(this.$store);

			return {
				IS_MOBILE: serverContextState.userAgent.deviceCategory === 'mobile',
				IS_TABLET: serverContextState.userAgent.deviceCategory === 'tablet',
				IS_DESKTOP: serverContextState.userAgent.deviceCategory === 'desktop',
			};
		},
	},
});
