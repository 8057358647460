// Blocking time optimized aca recommendations from
// https://github.com/nuxt/nuxt.js/discussions/9061#discussioncomment-539483

// Importing dependencies - statically ------
import { Context } from '@nuxt/types';
import { useClubStore } from '~/@api/store/clubApi';
import { PageTypes } from '~/@api/store.types';
import clientPluginOnNuxtReady from '~/plugins/clientPluginHandler';
import { importGlobalConstants } from '~/app-utils/dynamic-imports';

async function PluginIntegration(context: Context) {
	// Wait until all dependencies were loaded asynchronously in parallel
	const { api: clubApi } = useClubStore(context.store);
	const { CLUB_PROCESS } = await importGlobalConstants();

	const pageType = context.route.matched.find((match) => match.meta?.pageType)?.meta.pageType;

	if (pageType === PageTypes.CHECKOUT) {
		await clubApi.startClubProcess();
	}

	window.addEventListener('popstate', (event) => {
		if (event.state && event.state.component === CLUB_PROCESS) {
			clubApi.setCurrentStep(event.state.data.currentStep);
			clubApi.setClubId(event.state.data.clubId);
		}
	});
}

// Defer until Nuxt is ready
export default function (context: Context, _) {
	clientPluginOnNuxtReady(() => PluginIntegration(context));
}
