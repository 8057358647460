import type { Store } from 'vuex';
import { SpaType } from '@/generated/hybris-raml-api';
import { useServerContextStore } from '~/@api/store/serverContextApi';
import { RootState } from '~/@api/store.types';

const _url = (store: Store<RootState>, baseUrl: string, path = '', addCSRFToken = false) => {
	const { state: serverContextState } = useServerContextStore(store);
	const delimiter = path.includes('?') ? '&' : '?';
	const csrftoken = addCSRFToken
		? `${delimiter}CSRFToken=${serverContextState.session.csrfToken}`
		: '';
	const langPrefix = `${serverContextState.session.language}/shop`;
	const optionalSeparator = path.startsWith('/') ? '' : '/';
	const prefixedPath = path.includes(langPrefix)
		? `${optionalSeparator}${path}`
		: `/${langPrefix}${optionalSeparator}${path}`;

	return `${baseUrl}${prefixedPath}${csrftoken}`;
};

// ---------------------------------------------------------------------------
// Public API
// ---------------------------------------------------------------------------
export const url = (store: Store<RootState>, path = '', addCSRFToken = false) => {
	return _url(store, '', path, addCSRFToken);
};

export const apiUrl = (store: Store<RootState>, path = '', addCSRFToken = false) => {
	return _url(store, useServerContextStore(store).state.baseURL, path, addCSRFToken);
};

export const backend = {
	URL: {
		PRODUCTS_SEARCH: (store: Store<RootState>, query?: string) =>
			url(store, `/search${query ? '?text=' + encodeURIComponent(query) : ''}`),
		CODE: (store: Store<RootState>, path: string, heightFactor = 80) =>
			url(store, `${path}?heightFactor=${heightFactor}`),
	},
	API: {
		V1: {
			VOUCHER: (store: Store<RootState>, type: string, code: string) =>
				apiUrl(store, `/api/v1/cart/${type}/voucher?voucherCode=${code}`, true),
		},
		V2: {
			ADDRESS: {
				POINT_OF_SERVICE: {
					LIST: (store: Store<RootState>) => apiUrl(store, `/api/v2/address/pointOfService`),
					TRUSTIES: (store: Store<RootState>) =>
						apiUrl(store, `/api/v2/trusty?context=pdp_storeavailability`),
				},
				ZIP_SUGGESTION: (store: Store<RootState>, code: string) =>
					apiUrl(store, `/api/v2/address/zipSuggestion?zipPrefix=${code}`),
			},
			AUTOCOMPLETE: (store: Store<RootState>, term: string) =>
				apiUrl(store, `/api/v2/search/autocomplete/${encodeURIComponent(term)}`),
			BIKEFINDER: (store: Store<RootState>) => apiUrl(store, `/api/v2/bikeFinder`),
			BRANDS: (store: Store<RootState>) => apiUrl(store, `/api/v2/brands`),
			CART: {
				ADD: (store: Store<RootState>) => apiUrl(store, `/api/v2/cart/product`, true),
				INFO: (store: Store<RootState>, updateExternalStocks: boolean, recalculate = false) =>
					apiUrl(
						store,
						`/api/v2/cart?updateExternalStocks=${updateExternalStocks}&recalculate=${recalculate}`,
					),
				MODIFY: (store: Store<RootState>) => apiUrl(store, `/api/v2/cart/product/modify`, true),
				TRUSTIES: (store: Store<RootState>) => apiUrl(store, `/api/v2/trusty?context=cart`),
			},
			CATEGORIES: {
				LIST: (store: Store<RootState>) => apiUrl(store, `/api/v2/categories`),
			},
			CONTENT_NAVIGATION: (store: Store<RootState>) => apiUrl(store, `/api/v2/navigation/content`),
			CHECKOUT: {
				CONFIRMATION: {
					INFO: (store: Store<RootState>, orderCode: string) =>
						apiUrl(store, `/api/v2/checkout/confirmation/${orderCode}`),
					REDIRECT_URL: (store: Store<RootState>, orderCode: string) =>
						url(store, `/checkout/orderConfirmation/${orderCode}`),
					REGISTER_GUEST: (store: Store<RootState>, orderCode: string) =>
						apiUrl(store, `/api/v2/checkout/confirmation/${orderCode}/registerGuest`),
				},
				DELIVERY: {
					ADDRESS: (store: Store<RootState>) => apiUrl(store, `/api/v2/checkout/delivery/address`),
					CLICK_AND_COLLECT: (store: Store<RootState>) =>
						apiUrl(store, `/api/v2/checkout/delivery/clickAndCollect`),
					MODE: (store: Store<RootState>) => apiUrl(store, `/api/v2/checkout/delivery/mode`),
				},
				PAYMENT: {
					ADDRESS: (store: Store<RootState>) => apiUrl(store, `/api/v2/checkout/payment/address`),
					INFO: (store: Store<RootState>) => apiUrl(store, `/api/v2/checkout/payment/info`),
					INFORMATION: (store: Store<RootState>) =>
						apiUrl(store, `/api/v2/checkout/payment/information`),
					MODE: (store: Store<RootState>) => apiUrl(store, `/api/v2/checkout/payment/mode`),
				},
				START: (store: Store<RootState>) => apiUrl(store, `/api/v2/checkout/start`),
				STATE: (store: Store<RootState>) => apiUrl(store, `/api/v2/checkout`),
				SUMMARY: {
					PLACE_ORDER: (store: Store<RootState>) => apiUrl(store, `/api/v2/checkout/placeOrder`),
					TRUSTIES: (store: Store<RootState>) => apiUrl(store, `/api/v2/trusty?context=checkout`),
				},
			},
			CLUB: {
				ABORT: (store: Store<RootState>) => apiUrl(store, `/api/v2/club/process/abort`),
				INFORMATION: (store: Store<RootState>) => apiUrl(store, `/api/v2/club/process`),
				POS_REGISTER: (store: Store<RootState>) => apiUrl(store, `/api/v2/club/pos/register`),
				POS_INFO: (store: Store<RootState>) => apiUrl(store, `/api/v2/club/pos/info`),
				REGISTER: (store: Store<RootState>) => apiUrl(store, `/api/v2/club/process/register`),
				SMS: (store: Store<RootState>) => apiUrl(store, `/api/v2/club/sms`),
				START: (store: Store<RootState>) => apiUrl(store, `/api/v2/club/process/start`),
				STATUS: (store: Store<RootState>) => apiUrl(store, `/api/v2/club/status`),
			},
			COMMUNICATION_CENTER: {
				DEFAULT: (store: Store<RootState>) => apiUrl(store, '/api/v2/communication-center'),
				PROPERTY: (store: Store<RootState>) =>
					apiUrl(store, '/api/v2/communication-center/property'),
			},
			COMPONENTS: {
				HEADER: (store: Store<RootState>, uid: string) =>
					apiUrl(store, `/OSPHeaderComponentController/${uid}`),
				PAGE: (
					store: Store<RootState>,
					spaType: SpaType,
					identifier: string,
					location: string,
					full = false,
					cleanUrl = false,
				) =>
					apiUrl(
						store,
						`/api/v2/content/${spaType}/${encodeURIComponent(
							identifier,
						)}?full=${full}&location=${encodeURIComponent(location)}&cleanUrl=${cleanUrl}`,
					),
				SINGLE_COMPONENT: (store: Store<RootState>, uid: string) =>
					apiUrl(store, `/api/v2/content/${uid}`),
			},
			FACTFINDERCLICK: (store: Store<RootState>) => apiUrl(store, `/api/v2/search/factfinderClick`),
			FORM: {
				CONFIG: (store: Store<RootState>, formConfigCode: string) =>
					apiUrl(store, `/api/v2/form?code=${formConfigCode}`),
				CONTACT: (store: Store<RootState>) => apiUrl(store, `/api/v2/form/contact`),
			},
			GIFTCARD: {
				REDEEM: (store: Store<RootState>) => apiUrl(store, `/api/v2/giftcard/redeem`),
				RELEASE: (store: Store<RootState>) => apiUrl(store, `/api/v2/giftcard/release`),
			},
			INSTALLMENT: (store: Store<RootState>, amount: number) =>
				apiUrl(store, `/api/v2/installment?amount=${amount}`),
			NEWSLETTER: {
				SUBSCRIBE: (store: Store<RootState>) => apiUrl(store, `/api/v2/newsletter/subscribe`),
				UNSUBSCRIBE: (store: Store<RootState>) => apiUrl(store, `/api/v2/newsletter/unsubscribe`),
				UNSUBSCRIBE_CLUB: (store: Store<RootState>) =>
					apiUrl(store, `/api/v2/newsletter/unsubscribe-club`),
			},
			PRODUCT: (
				store: Store<RootState>,
				code: string,
				fields: string[] = [],
				updateExternalStocks = false,
			) =>
				apiUrl(
					store,
					`/api/v2/products/${code}?forceDb=true&updateExternalStocks=${updateExternalStocks}&fields=${fields.join(
						',',
					)}`,
				),
			PRODUCTS: (
				store: Store<RootState>,
				codes: string[],
				fields: string[] = [],
				updateExternalStocks = false,
			) =>
				apiUrl(
					store,
					`/api/v2/products/multi/${codes.join(
						',',
					)}?forceDb=true&updateExternalStocks=${updateExternalStocks}&fields=${fields.join(',')}`,
				),
			PRODUCT_POINTOFSERVICE: (store: Store<RootState>, code: string) =>
				apiUrl(store, `/api/v2/products/${code}/store`),
			PRODUCT_REDIRECT: (store: Store<RootState>, code: string) =>
				apiUrl(store, `/api/v2/products/${code}/redirect`),
			RECOMMENDATIONS: (store: Store<RootState>, code: string) =>
				apiUrl(store, `/api/v2/products/${code}/recommendations`),
			REVIEWS: (store: Store<RootState>, code: string) =>
				apiUrl(store, `/api/v2/products/${code}/reviews`),
			SEARCH: (store: Store<RootState>) => apiUrl(store, `/api/v2/search`),
			SERVER_SETTINGS: (store: Store<RootState>) => apiUrl(store, `/api/v2/serversettings`),
			SESSION: {
				CHANGE_GENDER: (store: Store<RootState>, code: string) =>
					apiUrl(store, `/api/v2/session/gender/${code}`),
				CHANGE_LANGUAGE: (store: Store<RootState>, isocode: string) =>
					apiUrl(store, `/api/v2/session/language/${isocode}`),
				CHANGE_EMAIL: (store: Store<RootState>) => apiUrl(store, `/api/v2/session/user/email`),
				CHANGE_PASSWORD: (store: Store<RootState>) =>
					apiUrl(store, `/api/v2/session/user/password`),
			},
			SPA: (store: Store<RootState>, type: string, id: string, storeI18nUrls = false) => {
				const all = apiUrl(store, `/api/v2/spa/${type}/${encodeURIComponent(id)}`);

				return {
					all: `${all}?storeI18nUrls=${storeI18nUrls}`,
					digitalData: `${all}/digital-data`,
					meta: `${all}/meta`,
					richSnippets: `${all}/rich-snippets`,
					storeI18nUrls: `${all}/store-i18n-urls`,
				};
			},
			SSR: {
				INITIALIZATION: (store: Store<RootState>, location: string) =>
					`${
						useServerContextStore(store).state.baseURL
					}/api/v2/ssr/initialization?location=${encodeURIComponent(location)}`,
			},
			TRANSLATIONS: (store: Store<RootState>, isocode?: string) =>
				apiUrl(store, `/api/v2/translations${isocode ? '/' : ''}${isocode}`),
			USER: {
				ADDRESSBOOK: {
					ADD: (store: Store<RootState>) => apiUrl(store, `/api/v2/customer/address`),
					EDIT: (store: Store<RootState>) => apiUrl(store, `/api/v2/customer/address`),
					INFO: (store: Store<RootState>) => apiUrl(store, `/api/v2/customer/address`),
					REMOVE: (store: Store<RootState>, addressId: string) =>
						apiUrl(store, `/api/v2/customer/address/${addressId}`),
				},
				FORGOTTEN_PASSWORD: (store: Store<RootState>) =>
					apiUrl(store, `/api/v2/customer/forgottenPassword`, true),
				GIFTCARDS: (store: Store<RootState>, update = false, hideAlreadyUsed = true) =>
					apiUrl(
						store,
						`/api/v2/customer/giftcards?updateValues=${update}&hideAlreadyUsed=${hideAlreadyUsed}`,
					),
				LOGIN: (store: Store<RootState>) => apiUrl(store, `/j_spring_security_check`, true),
				LOGIN_CHECKOUT: (store: Store<RootState>) =>
					apiUrl(store, `/checkout/j_spring_security_check`, true),
				PASSWORD_RESET: (store: Store<RootState>) => apiUrl(store, `/api/v2/password-reset`, true),
				REGISTRATION: (store: Store<RootState>) => apiUrl(store, `/api/v2/customer`, true),
				REGISTRATION_GUEST: (store: Store<RootState>) => apiUrl(store, `/api/v2/guest`, true),
				REGISTRATION_INFORMATION: (store: Store<RootState>) =>
					apiUrl(store, `/api/v2/customer/loginInformation`),
				SESSION_USER: (store: Store<RootState>) => apiUrl(store, `/api/v2/session/user`),
				TRUSTIES_LOGIN: (store: Store<RootState>) =>
					apiUrl(store, `/api/v2/trusty?context=checkout_login`),
				PAYMENT: {
					DELETE: (store: Store<RootState>) => apiUrl(store, '/api/v2/paymentDeletion'),
				},
			},
			MY_ACCOUNT: {
				START: (store: Store<RootState>) => url(store, '/my-account/start.html'),
				NAVIGATION: (store: Store<RootState>) => apiUrl(store, `/api/v2/my-account/navigation`),
				ORDER: {
					HISTORY: (store: Store<RootState>, amount?: number) =>
						apiUrl(store, `/api/v2/my-account/order/history${amount ? '?amount=' + amount : ''}`),
					DETAILS: (store: Store<RootState>, orderCode: string) =>
						apiUrl(store, `/api/v2/my-account/order/details/${orderCode}`),
					CANCEL: (store: Store<RootState>, orderCode: string) =>
						apiUrl(store, `/api/v2/my-account/order/cancel/${orderCode}`),
				},
				PERSONAL_DETAILS: (store: Store<RootState>) =>
					url(store, '/api/v2/my-account/personal-details'),
			},
		},
	},
};
