import type { Store, Module } from 'vuex';
import { RootState } from '~/@api/store.types';

interface UseModule<STATE, API> {
	state: STATE;
	api: API;
}

type ApiConstructor<API> = (store: Store<RootState>) => API;

export function useStoreModule<STATE, API>(
	name: string,
	store: Store<RootState>,
	module: Module<STATE, RootState>,
	api: ApiConstructor<API>,
): UseModule<STATE, API> {
	if (process.server) {
		return createUseModule(name, store, module, api);
	}

	const useStoreFunctions = getUseStoreFunctions();

	if (!useStoreFunctions[name]) {
		useStoreFunctions[name] = createUseModule(name, store, module, api);
	}

	return useStoreFunctions[name];
}

function getUseStoreFunctions<STATE, API>(): UseModule<STATE, API> {
	(window as any).osp = {
		...(window as any).osp,
		useStoreFunctions: {
			...(window as any).osp?.useStoreFunctions,
		},
	};

	return (window as any).osp.useStoreFunctions;
}

function createUseModule<STATE, API>(
	name: string,
	store: Store<RootState>,
	module: Module<STATE, RootState>,
	api: ApiConstructor<API>,
): UseModule<STATE, API> {
	if (!store.hasModule(name)) {
		store.registerModule(name, { namespaced: true, ...module });
	}

	return {
		api: api(store),
		state: store.state[name],
	};
}
