import type { Store } from 'vuex';
import { useStoreModule } from '../useStoreModule';
import clsStore from './cls.store';
import clsApi from './cls.api';
import { ClsState, RootState } from '~/@api/store.types';
import { CLS } from '~/@constants/store';

export function useClsStore(store: Store<RootState>) {
	return useStoreModule<ClsState, ReturnType<typeof clsApi>>(CLS, store, clsStore, clsApi);
}
