import { Context } from '@nuxt/types';
import Logger from '@/node_modules/@osp/utils/src/logger';
import {
	Breakpoint,
	HybrisSession,
	ServerContextState,
	UserAgent,
	UserAgentDeviceCategory,
} from '~/@api/store.types';
import {
	mapFn,
	SERVERCONTEXT_M_INIT,
	SERVERCONTEXT_M_RESOLUTION,
	SERVERCONTEXT_M_SAVE_HYBRIS_SESSION,
} from '~/@constants/store';
import { FORCE_DEVICE } from '~/@constants/global';

// Initial state -----------------------------------------------------------------------------------

const state = (): ServerContextState => ({
	baseURL: process.env.BASE_URL,
	env: {
		DEBUG: process.env.DEBUG,
		tracking: {
			performance: {
				active: process.env.TRACKING_PERFORMANCE === 'true',
				debug: process.env.TRACKING_PERFORMANCE_DEBUG === 'true',
			},
			cls: {
				active: process.env.TRACKING_CLS === 'true',
				thresholds: {
					total: parseFloat(process.env.TRACKING_CLS_THRESHOLD_TOTAL) || 0.0,
					page: parseFloat(process.env.TRACKING_CLS_THRESHOLD_PAGE) || 0.0,
					item: parseFloat(process.env.TRACKING_CLS_THRESHOLD_ITEM) || 0.0,
				},
			},
			apis: {
				active: process.env.TRACKING_API_REQUESTS === 'true',
			},
			longTasks: {
				active: process.env.TRACKING_LONG_TASKS === 'true',
			},
			spaRumThresholdReporting: {
				active: process.env.TRACKING_SPA_RUM_THRESHOLD_REPORTING === 'true',
				thresholds: {
					lcp: parseInt(process.env.TRACKING_SPA_RUM_LCP_THRESHOLD),
					fcp: parseInt(process.env.TRACKING_SPA_RUM_FCP_THRESHOLD),
					inp: parseInt(process.env.TRACKING_SPA_RUM_INP_THRESHOLD),
					fid: parseInt(process.env.TRACKING_SPA_RUM_FID_THRESHOLD),
					tti: parseInt(process.env.TRACKING_SPA_RUM_TTI_THRESHOLD),
					tbt: parseInt(process.env.TRACKING_SPA_RUM_TBT_THRESHOLD),
				},
			},
		},
		timeouts: {
			apiRequestExternalSsr: parseInt(process.env.API_REQUEST_TIMEOUT_EXTERNAL_SSR),
			apiRequestExternalClient: parseInt(process.env.API_REQUEST_TIMEOUT_EXTERNAL_CLIENT),
		},
	},
	session: {
		clsOptimizationEnabled: false,
		cookies: null,
		csrfToken: null,
		debugEnabled: false,
		dynamicYieldApiToken: '',
		dynamicYieldAsync: true,
		dynamicYieldSectionId: '',
		googleTagManagerContainerId: null,
		googleTagManagerEnabled: false,
		clientIPAddress: null,
		language: null,
		trackingEnabled: false,
		smartProxyEnabled: false,
	},
	userAgent: null,
});

// Mutations ---------------------------------------------------------------------------------------

const mutations = {
	[mapFn(SERVERCONTEXT_M_INIT)](_state: ServerContextState, serverCtx: Context) {
		_state.session.clientIPAddress =
			(serverCtx.req?.headers['x-forwarded-for'] as string) || serverCtx.req?.socket?.remoteAddress;
		_state.session.cookies = serverCtx.req?.headers?.cookie;
		_state.userAgent = mapDeviceCategory(serverCtx);
	},

	[mapFn(SERVERCONTEXT_M_RESOLUTION)](_state: ServerContextState, resolution: Breakpoint) {
		_state.userAgent.resolution = resolution;
	},

	[mapFn(SERVERCONTEXT_M_SAVE_HYBRIS_SESSION)](
		_state: ServerContextState,
		hybrisSession: HybrisSession,
	) {
		_state.session = hybrisSession;

		Logger.trace('ServerContext', _state);
	},
};

export default {
	state,
	mutations,
};

// Helpers -----------------------------------------------------------------------------------------

const mapDeviceCategory = (serverCtx: Context): UserAgent => {
	// Check if a cookie value exists, for previously used and stored device category
	let deviceCategory = (serverCtx.$cookies.get('ospDeviceCategory') ||
		serverCtx.route.query[FORCE_DEVICE]) as UserAgentDeviceCategory;

	if (!deviceCategory) {
		switch (serverCtx.$ua.deviceType()) {
			case 'crawler':
			case 'smartphone':
			case 'mobilephone': {
				deviceCategory =
					serverCtx.$ua.isFromCrawler() || !serverCtx.$ua.isFromTablet() ? 'mobile' : 'tablet';
				break;
			}
			default:
				deviceCategory = 'desktop';
		}
	}

	return {
		deviceCategory,
		resolution: undefined,
		isBot: serverCtx.$ua.isFromCrawler(),
		isRetina: process.client
			? window.devicePixelRatio > 1
			: deviceCategory === 'mobile' || deviceCategory === 'tablet',
	};
};
