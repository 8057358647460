import type { Store } from 'vuex';
import { useStoreModule } from '../useStoreModule';
import checkoutStore from './checkout.store';
import checkoutApi from './checkout.api';
import { CheckoutModuleState, RootState } from '~/@api/store.types';
import { CHECKOUT } from '~/@constants/store';

export function useCheckoutStore(store: Store<RootState>) {
	return useStoreModule<CheckoutModuleState, ReturnType<typeof checkoutApi>>(
		CHECKOUT,
		store,
		checkoutStore,
		checkoutApi,
	);
}
