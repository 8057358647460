import type { Store } from 'vuex';
import { useStoreModule } from '../useStoreModule';
import dynamicyieldStore from './dynamicyield.store';
import dynamicyieldApi from './dynamicyield.api';
import { DynamicYieldState, RootState } from '~/@api/store.types';
import { DYNAMICYIELD } from '~/@constants/store';

export function useDynamicyieldStore(store: Store<RootState>) {
	return useStoreModule<DynamicYieldState, ReturnType<typeof dynamicyieldApi>>(
		DYNAMICYIELD,
		store,
		dynamicyieldStore,
		dynamicyieldApi,
	);
}
