import { Context } from '@nuxt/types';
import _findLast from 'lodash-es/findLast';
import _isEmpty from 'lodash-es/isEmpty';
import { RouteRecord } from 'vue-router';
import { elapsedTime } from '@/node_modules/@osp/utils/src/performance';
import { routingCheckoutSteps, RoutingStepCheckout } from '@/routing/checkout-steps';
import { url } from '~/@api/backend';
import { useCheckoutStore } from '~/@api/store/checkoutApi';
import { useMessageboxStore } from '~/@api/store/messageboxApi';
import { CheckoutStep } from '~/generated/hybris-raml-api';

export default async (context: Context) => {
	const checkoutRoute = _findLast(
		context.route.matched,
		(route) => route.meta.checkoutStep,
	) as RouteRecord;

	if (checkoutRoute) {
		const isFirstRoute = _isEmpty(context.route.matched); // pageLoad

		useMessageboxStore(context.store).api.dismissAll('checkout');

		const currentCheckoutStep = checkoutRoute.meta.checkoutStep as RoutingStepCheckout;

		if (currentCheckoutStep !== routingCheckoutSteps.START) {
			const { api: checkoutApi, state: checkoutState } = useCheckoutStore(context.store);
			let allowedSteps: CheckoutStep[] = checkoutState.allowedSteps;

			if (!allowedSteps || allowedSteps.length === 0) {
				await checkoutApi.update();

				if (isFirstRoute) return;

				allowedSteps = checkoutState.allowedSteps;
			}

			const RoutingStepsCheckout = allowedSteps.map((step) => routingCheckoutSteps[step]);
			const lastCheckoutStepPassed = checkManageLastCheckoutStepPassed(
				context,
				RoutingStepsCheckout,
				currentCheckoutStep,
			);

			if (!lastCheckoutStepPassed) {
				checkManageMoreStepsToGo(context, RoutingStepsCheckout, currentCheckoutStep);
			}
		}
	}

	elapsedTime('middleware: checkout-guard');
};

function currentStepOrMoreStepsToGo(allowedSteps, currentCheckoutStep): boolean {
	return allowedSteps.some((allowedStep) => allowedStep.order >= currentCheckoutStep.order);
}

function checkManageMoreStepsToGo(
	context: Context,
	allowedSteps: RoutingStepCheckout[],
	currentCheckoutStep: RoutingStepCheckout,
): boolean {
	if (!currentStepOrMoreStepsToGo(allowedSteps, currentCheckoutStep)) {
		return false;
	}

	const { api: checkoutApi } = useCheckoutStore(context.store);

	if (allowedSteps.includes(currentCheckoutStep)) {
		checkoutApi.setCurrentStepMutation(CheckoutStep[currentCheckoutStep.code]);

		return true;
	}

	const nextAllowedStep = allowedSteps
		.filter((allowedStep) => allowedStep.order >= currentCheckoutStep.order)
		.sort((a, b) => a.order - b.order)[0];

	checkoutApi.setCurrentStepMutation(CheckoutStep[nextAllowedStep.code]);
	context.redirect(302, url(context.store, nextAllowedStep.path));

	return true;
}

function sortStepsByOrder(stepsToSort: RoutingStepCheckout[]): RoutingStepCheckout[] {
	return stepsToSort.sort((stepA, stepB) => stepA.order - stepB.order);
}

function checkManageLastCheckoutStepPassed(
	context: Context,
	allowedSteps: RoutingStepCheckout[],
	currentCheckoutStep: RoutingStepCheckout,
): boolean {
	if (currentStepOrMoreStepsToGo(allowedSteps, currentCheckoutStep)) {
		return false;
	}

	const sortedSteps = sortStepsByOrder(allowedSteps);
	const lastAllowedStep = _findLast(sortedSteps);

	if (lastAllowedStep) {
		context.redirect(302, url(context.store, lastAllowedStep.path));

		return true;
	}

	context.redirect(
		302,
		// Find first checkout step
		url(
			context.store,
			Object.values(routingCheckoutSteps)
				.filter((checkoutStep) => checkoutStep.group === 1)
				.sort((a, b) => a.order - b.order)[0].path,
		),
	);

	return true;
}
