// Blocking time optimized aca recommendations from
// https://github.com/nuxt/nuxt.js/discussions/9061#discussioncomment-539483

// Importing dependencies - statically ------
import { Context } from '@nuxt/types';
import { Cluster } from 'ioredis';
import { Inject } from '@nuxt/types/app';
import { stringify } from '@/node_modules/@osp/design-system/assets/js/utilities/stringify';

// Importing dependencies - dynamically ------
import { importLogger } from '~/app-utils/dynamic-imports';

// --------------------------------------------------------------------------
// Typings
// --------------------------------------------------------------------------

declare module '@nuxt/types' {
	interface Context {
		$cache: Cache;
	}
}

declare module 'vue/types/vue' {
	interface Vue {
		$cache: Cache;
	}
}

declare module 'vuex/types/index' {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	interface Store<S> {
		$cache: Cache;
	}
}

type CacheTimouts = { [key: string]: number };

export interface CacheOptions {
	blacklist?: RegExp[];
	redis?: () => Cluster;
	timeouts: CacheTimouts;
}

// --------------------------------------------------------------------------

export class Cache {
	private store: Cluster;
	private timeouts: CacheTimouts;
	private blacklist: RegExp[];

	constructor(options: CacheOptions) {
		if (process.server) {
			this.blacklist = options.blacklist || [];
			this.store = options.redis();
			this.timeouts = options.timeouts;
		}
	}

	disconnect() {
		if (process.server && this.store) {
			this.store.disconnect();
		}
	}

	private _findTimout = (key: string) =>
		this.timeouts[Object.keys(this.timeouts).filter((name) => RegExp(name).test(key))[0]] ||
		this.timeouts.default;

	private _isNotBlacklisted = (key: string) => !this.blacklist.some((regex) => regex.test(key));

	private _isCacheEnabled = (key: string) => this._findTimout(key) >= 0;

	async set(key: string, value: any, expires?: number) {
		if (process.server && this.store && this._isNotBlacklisted(key) && this._isCacheEnabled(key)) {
			try {
				const timeout = expires || this._findTimout(key);

				if (timeout > 0) {
					// TODO: Check from time to time if we can remove type assertion after 'ioredis' NPM Package update
					// (type assertion is added for now to get rid of an error during build)
					await (this.store as any).set(key, stringify(value), 'EX', timeout);
				} else {
					// TODO: Check from time to time if we can remove type assertion after 'ioredis' NPM Package update
					// (type assertion is added for now to get rid of an error during build)
					await (this.store as any).set(key, stringify(value));
				}
			} catch (e) {
				importLogger().then(({ default: logger }) =>
					logger.warn('Redis cache not accessible. Cannot set', key, value, e),
				);
			}
		}
	}

	async get<T>(key: string) {
		if (process.server && this.store && this._isNotBlacklisted(key)) {
			try {
				// TODO: Check from time to time if we can remove type assertion after 'ioredis' NPM Package update
				// (type assertion is added for now to get rid of an error during build)
				const rawValue = await (this.store as any).get(key);

				if (typeof rawValue === 'string') {
					try {
						return JSON.parse(rawValue) as T;
					} catch (e) {
						return rawValue;
					}
				}

				return rawValue;
			} catch (e) {
				importLogger().then(({ default: logger }) =>
					logger.warn('Redis cache not accessible. Cannot get', key, e),
				);
			}
		}
	}
}

// --------------------------------------------------------------------------
// Inject the cache into vue, context and store
// --------------------------------------------------------------------------

export default function (context: Context, inject: Inject) {
	inject('cache', new Cache(context.$config.cache));
}
