import type { Store } from 'vuex';
import { useStoreModule } from '../useStoreModule';
import voucherStore from './voucher.store';
import voucherApi from './voucher.api';
import { RootState, VoucherState } from '~/@api/store.types';
import { VOUCHER } from '~/@constants/store';

export function useVoucherStore(store: Store<RootState>) {
	return useStoreModule<VoucherState, ReturnType<typeof voucherApi>>(
		VOUCHER,
		store,
		voucherStore,
		voucherApi,
	);
}
