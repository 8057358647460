// Blocking time optimized aca recommendations from
// https://github.com/nuxt/nuxt.js/discussions/9061#discussioncomment-539483

// Importing dependencies - statically ------
import { Context } from '@nuxt/types';
import { Inject } from '@nuxt/types/app';
import { useMediaqueryStore } from '~/@api/store/mediaqueryApi';
import { useServerContextStore } from '~/@api/store/serverContextApi';
import { Breakpoint, UserAgentDeviceCategory } from '~/@api/store.types';
import { MEDIAQUERY_M_DIMENSIONS } from '~/@constants/store';
import { FORCE_DEVICE } from '~/@constants/global';

// Importing dependencies - dynamically ------
import {
	importLodashForEach,
	importLodashThrottle,
	importLogger,
	importRunTask,
	importSsm,
} from '~/app-utils/dynamic-imports';

const Mediaqueries = {
	[Breakpoint.MOBILE]: `(max-width: 767px) and (orientation: portrait)`,
	[Breakpoint.MOBILE_LANDSCAPE]: `(max-width: 767px) and (orientation: landscape)`,
	[Breakpoint.TABLET]: `(min-width: 768px) and (max-width: 1024px) and (orientation: portrait)`,
	[Breakpoint.TABLET_LANDSCAPE]: `(min-width: 768px) and (max-width: 1024px) and (orientation: landscape)`,
	[Breakpoint.DESKTOP]: `(min-width: 1025px)`,
};

// No performance optimization by deferring or async execution of main plugin code to ensure app
// waits with rendering until breakpoints are set
export default function (context: Context, _inject: Inject) {
	context.app.ssm = {
		initialized: false,
		initialize() {
			if (!this.initialized) {
				// Wait until all dependencies were loaded asynchronously in parallel
				Promise.all([importLogger(), importLodashForEach(), importSsm(), importRunTask()]).then(
					(responses) => {
						const Logger = responses[0].default;
						const _forEach = responses[1].default;
						const ssm = responses[2].default;
						const runTask = responses[3].runTask;

						const mediaQueryStore = useMediaqueryStore(context.store);

						Logger.debug('initializing simple state manager');

						this.initialized = true;

						_forEach(Mediaqueries, (query: string, resolutionBreakpoint: Breakpoint) => {
							ssm.addState({
								resolutionBreakpoint,
								onEnter: () =>
									runTask(() => {
										useServerContextStore(context.store).api.setResolution(resolutionBreakpoint);

										// Avoid switching from desktop to any other breakpoint, to leave decision to user
										if (
											mediaQueryStore.state.currentBreakpoint === 'desktop' &&
											resolutionBreakpoint !== 'desktop'
										) {
											return;
										}

										mediaQueryStore.api.update(resolutionBreakpoint);
									}),
								query,
							});
						});

						fixDeviceCategoryForSSR(context, ssm);
					},
				);
			}
		},
	};

	// Handle window resize events and update Vuex store
	importLodashThrottle().then((importedModule) => {
		window.addEventListener(
			'resize',
			importedModule.default(
				(event) => {
					importRunTask().then(({ runTask }) => {
						runTask(() => {
							const { state: mediaqueryState } = useMediaqueryStore(context.store);

							if (
								mediaqueryState.dimensions.width !== window.innerWidth ||
								mediaqueryState.dimensions.height !== window.innerHeight ||
								// The event detail 1337 is used to force a resize -> triggered
								// by manual resize event from tiny-slider.ts
								event.detail === 1337
							) {
								context.store.commit(MEDIAQUERY_M_DIMENSIONS, {
									width: window.innerWidth,
									height: window.innerHeight,
								});
							}
						});
					});
				},
				150,
				{ leading: true, trailing: true },
			),
		);
	});
}

const fixDeviceCategoryForSSR = (context: Context, ssm) => {
	// Remove query parameters from URL
	if (context.route.query[FORCE_DEVICE]) {
		return cleanUpForceDevice(context);
	}

	// Redirect to mobile when no mobile device and mobile breakpoint
	if (!isMobileDevice(context) && isMobileResolution(ssm)) {
		return forceDevice(context, 'mobile');
	}

	// Redirect to desktop when tablet device and desktop breakpoint
	if (!isDesktopDevice(context) && isDesktopResolution(ssm)) {
		return forceDevice(context, 'desktop');
	}
};

const isMobileDevice = (context: Context) =>
	useServerContextStore(context.store).state.userAgent.deviceCategory === 'mobile';

const isDesktopDevice = (context: Context) =>
	useServerContextStore(context.store).state.userAgent.deviceCategory === 'desktop';

const isMobileResolution = (ssm) =>
	ssm.isActive(Breakpoint.MOBILE) || ssm.isActive(Breakpoint.MOBILE_LANDSCAPE);

const isDesktopResolution = (ssm) => ssm.isActive(Breakpoint.DESKTOP);

const forceDevice = (context: Context, deviceCategory: UserAgentDeviceCategory) =>
	(window.location.href = `${context.route.fullPath}${
		Object.keys(context.route.query).length > 0 ? '&' : '?'
	}${FORCE_DEVICE}=${deviceCategory}`);

const cleanUpForceDevice = (context: Context) =>
	context.$router.replace(
		context.route.fullPath.replace(/([?&])force-device=(mobile|tablet|desktop)/g, ''),
	);
