import type { Store } from 'vuex';
import { useStoreModule } from '../useStoreModule';
import clubStore from './club.store';
import clubApi from './club.api';
import { ClubState, RootState } from '~/@api/store.types';
import { CLUB } from '~/@constants/store';

export function useClubStore(store: Store<RootState>) {
	return useStoreModule<ClubState, ReturnType<typeof clubApi>>(CLUB, store, clubStore, clubApi);
}
