import { Context } from '@nuxt/types';

// eslint-disable-next-line require-await
async function PluginIntegration(context: Context) {
	(window as any).sentry = context.app.$sentry;
}

// Do not defer this plugin until Nuxt is ready, to have sentry asap
export default function (context: Context) {
	PluginIntegration(context);
}
